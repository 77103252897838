import { dependencies } from '@pn/core/dependencies';
import {
  formatDataType,
  isPNDataType,
  type DataItemId,
  type PNDataType,
} from '@pn/core/domain/data';
import type { ProductionChartType } from '@pn/core/domain/production';
import { handleError } from '@pn/core/errors/handleError';
import {
  productionActions,
  useProductionStorage,
} from '@pn/core/storage';
import { apiProductionProvider } from '@pn/services/api/production/apiProductionProvider';
import { isArray, isEmpty, isString } from 'lodash-es';
import React from 'react';

let lastIdRequested: DataItemId = '';
async function getDataProduction(
  dataType: PNDataType, // have to narrow the type here
  id: DataItemId
): Promise<void> {
  try {
    lastIdRequested = id;
    productionActions(dataType).request();

    const productionItems = await apiProductionProvider.getProduction(
      dataType,
      id
    );

    if (lastIdRequested !== id) return;
    productionActions(dataType).receive(productionItems);
  } catch (error) {
    handleError({
      error,
      onError: productionActions(dataType).error,
      userFriendlyMessage: `Failed to get ${formatDataType(dataType, {
        form: 'singular',
      })} production`,
    });
  }
}

async function getListAggregateProduction(listId: string): Promise<void> {
  try {
    productionActions('list_aggregate').request();

    const productionItems =
      await apiProductionProvider.getListAggregateProduction(listId);

    productionActions('list_aggregate').receive(productionItems);
  } catch (error) {
    handleError({
      error,
      onError: productionActions('list_aggregate').error,
      userFriendlyMessage: `Failed to get list aggregate production`,
    });
  }
}

async function getListCompareProduction(
  listId: string,
  ids: DataItemId[]
): Promise<void> {
  try {
    ids.forEach((id) => itemIdsFetched.add(id));

    productionActions('list_compare').request();

    const productionItems =
      await apiProductionProvider.getListCompareProduction(listId, ids);

    productionActions('list_compare').receive(productionItems);
  } catch (error) {
    handleError({
      error,
      onError: productionActions('list_compare').error,
      userFriendlyMessage: `Failed to get list comparison production`,
    });
  }
}

async function getAggregateProduction(wellIds: string[]): Promise<void> {
  try {
    productionActions('aggregate').request();

    const productionItems =
      await apiProductionProvider.getAggregateProduction(wellIds);

    productionActions('aggregate').receive(productionItems);
  } catch (error) {
    handleError({
      error,
      onError: productionActions('aggregate').error,
      userFriendlyMessage: `Failed to get aggregate production`,
    });
  }
}

async function getCompareProduction(
  wellIds: string[],
  selectedWellIds: string[]
): Promise<void> {
  try {
    productionActions('compare').request();

    const productionItems = await apiProductionProvider.getCompareProduction(
      wellIds,
      selectedWellIds
    );

    productionActions('compare').receive(productionItems);
  } catch (error) {
    handleError({
      error,
      onError: productionActions('aggregate').error,
      userFriendlyMessage: `Failed to get aggregate production`,
    });
  }
}

async function getExtraDataItemsProduction(
  productionChartType: ProductionChartType,
  dataType: PNDataType, // have to narrow the type here
  ids: DataItemId[]
): Promise<void> {
  try {
    ids.forEach((id) => itemIdsFetched.add(id));

    productionActions(productionChartType).requestExtra();

    const productionItems = await apiProductionProvider.getMultiProduction(
      dataType,
      ids
    );

    productionActions(productionChartType).receiveExtra(productionItems);
  } catch (error) {
    handleError({
      error,
      onError: productionActions('list_compare').error,
      userFriendlyMessage: `Failed to get ${ids.length} ${formatDataType(
        dataType
      )} production`,
    });
  }
}

// FIXME needs rework
const itemIdsFetched = new Set<DataItemId>();
const listAggregateProductionFetched = new Set<string>();
const listCompareProductionFetched = new Set<string>();

export function useAutoGetProduction(params: {
  productionChartType: ProductionChartType;
  id: string | number | undefined;
  selectedItemIds?: DataItemId[];
  listDataType?: string;
}) {
  const { isAuthenticated } = dependencies.useAuthenticationService();
  const { idsLoaded: listCompareIdsLoaded } =
    useProductionStorage('list_compare');

  const listCompareIdsToLoad = React.useMemo(
    () =>
      (params.selectedItemIds ?? []).filter(
        (id) => !itemIdsFetched.has(id) && !listCompareIdsLoaded.includes(id)
      ),
    [params.selectedItemIds, listCompareIdsLoaded]
  );

  React.useEffect(() => {
    if (
      !isAuthenticated ||
      !isPNDataType(params.productionChartType) ||
      !isString(params.id)
    )
      return;

    getDataProduction(params.productionChartType, params.id);
  }, [isAuthenticated, params.productionChartType, params.id]);

  React.useEffect(() => {
    if (
      !isAuthenticated ||
      params.productionChartType !== 'list_aggregate' ||
      !isString(params.id) ||
      listAggregateProductionFetched.has(params.id)
    )
      return;

    listAggregateProductionFetched.add(params.id);
    getListAggregateProduction(params.id);
  }, [isAuthenticated, params.productionChartType, params.id]);

  React.useEffect(() => {
    if (
      !isAuthenticated ||
      params.productionChartType !== 'list_compare' ||
      !isString(params.id)
    )
      return;

    /* First load */
    if (
      isEmpty(listCompareIdsLoaded) &&
      isArray(params.selectedItemIds) &&
      !listCompareProductionFetched.has(params.id)
    ) {
      listCompareProductionFetched.add(params.id);
      getListCompareProduction(params.id, params.selectedItemIds);
      return;
    }

    /* Extra loads */
    const dataType = params.listDataType;
    if (!isEmpty(listCompareIdsToLoad) && isPNDataType(dataType)) {
      getExtraDataItemsProduction(
        'list_compare',
        dataType,
        listCompareIdsToLoad
      );
    }
  }, [
    isAuthenticated,
    params.productionChartType,
    params.id,
    params.selectedItemIds,
    params.listDataType,
    listCompareIdsLoaded,
    listCompareIdsToLoad,
  ]);

  React.useEffect(() => {
    if (!isAuthenticated || params.productionChartType !== 'aggregate') return;
    const lsWellsIds = localStorage.getItem('production-well-ids');
    if (!isString(lsWellsIds)) return;
    const wellIds = lsWellsIds.split(',');

    getAggregateProduction(wellIds);
  }, [isAuthenticated, params.productionChartType]);

  React.useEffect(() => {
    if (!isAuthenticated || params.productionChartType !== 'compare') return;
    const lsWellsIds = localStorage.getItem('production-well-ids');
    if (!isString(lsWellsIds)) return;
    const wellIds = lsWellsIds.split(',');

    if (
      isEmpty(listCompareIdsLoaded) &&
      !listCompareProductionFetched.has('compare')
    ) {
      listCompareProductionFetched.add('compare');
      getCompareProduction(wellIds, []);
      return;
    }

    if (!isEmpty(listCompareIdsToLoad)) {
      getExtraDataItemsProduction('compare', 'wells', listCompareIdsToLoad);
    }
  }, [
    isAuthenticated,
    listCompareIdsLoaded,
    listCompareIdsToLoad,
    params.productionChartType,
  ]);
}
