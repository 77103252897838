import { useCopyPaste } from '@pn/services/drawing/hooks/useCopyPaste';
import { useCursor } from '@pn/services/drawing/hooks/useCursor';
import { useCustomPanning } from '@pn/services/drawing/hooks/useCustomPanning';
import { useDelete } from '@pn/services/drawing/hooks/useDelete';
import { useHistory } from '@pn/services/drawing/hooks/useHistory';
import { useHoverFeature } from '@pn/services/drawing/hooks/useHoverFeature';
import { useMoveVertex } from '@pn/services/drawing/hooks/useMoveVertex';
import { useMultiSelectTool } from '@pn/services/drawing/hooks/useMultiSelectTool';
import { useResizeFeature } from '@pn/services/drawing/hooks/useResizeFeature';
import { useSelectTool } from '@pn/services/drawing/hooks/useSelectTool';
import { useVisualizeDrawingItems } from '@pn/services/drawing/hooks/useVisualizeDrawingItems';
import { useCircleTool } from '@pn/services/drawing/tools/useCircleTool';
import { useFreehandTool } from '@pn/services/drawing/tools/useFreehandTool';
import { useLineTool } from '@pn/services/drawing/tools/useLineTool';
import { useRectangleTool } from '@pn/services/drawing/tools/useRectangleTool';
import { useTextTool } from '@pn/services/drawing/tools/useTextTool';

export function useDrawingLogic(itemId: string) {
  useCustomPanning(); // has to be called before other hooks

  useHoverFeature(itemId);
  useResizeFeature();
  useMoveVertex();
  useSelectTool();
  useMultiSelectTool(itemId);

  useFreehandTool(itemId);
  useLineTool(itemId);
  useRectangleTool(itemId);
  useCircleTool(itemId);
  useTextTool(itemId);

  useHistory();
  useCopyPaste(itemId);
  useDelete();

  useCursor();

  useVisualizeDrawingItems();
}
