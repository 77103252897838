import { generateTemporaryId } from '@pn/core/utils/id';
import type { Channel } from './channel';
import type { ISODateTimeString } from './types';
import { userToProjectUser, type ProjectUser, type User } from './user';
import type { WorkspaceItem } from './workspace';

export type Project = {
  id: string;
  name: string;
  folder: string;
  createdAt: ISODateTimeString;
  updatedAt: ISODateTimeString;
  createdBy: ProjectUser | undefined;
  channelId: Channel['id'] | undefined;
  workspaceItemIds: WorkspaceItem['id'][];
  toggleAllEnabled: boolean;
  isGlobal: boolean;
  origin: 'pn' | 'stackdx';
  metadata?: {
    dataOriginDescription?: string;
  }
};

export type ProjectForm = Pick<
  Project,
  'name' | 'channelId' | 'workspaceItemIds'
>;

export function createProject(projectForm: ProjectForm, user: User): Project {
  return {
    ...projectForm,
    id: generateTemporaryId(),
    folder: 'Personal',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    createdBy: userToProjectUser(user),
    toggleAllEnabled: true,
    isGlobal: false,
    origin: 'pn',
  };
}

/**
 * @deprecated
 */
export type ProjectItem = {
  id: string;
  name: string;
  createdBy: ProjectUser | undefined;
  createdAt: ISODateTimeString;
  updatedAt: ISODateTimeString;
  isGlobal: boolean;
};
