import LockIcon from '@mui/icons-material/Lock';
import { Box, ListItem, ListItemButton, ListItemText } from '@mui/material';
import {
  WorkspaceItem,
  getItemColor,
} from '@pn/core/domain/workspace';
import {
  generateIconBackground,
  getNormalizedColor,
} from '@pn/services/utils/color';
import { isString } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ color: string; background: string }>()(
  (theme, { color, background }) => ({
    listItem: {
      '.MuiListItemSecondaryAction-root': {
        right: 8,
      },
    },
    listItemButton: {
      height: 32,
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(0.5),
      '& .MuiListItemSecondaryAction-root': {
        right: theme.spacing(1),
      },
    },
    listItemIcon: {
      minWidth: 36,
      color,
    },
    loadingIndicator: {
      position: 'relative',
      left: -3,
    },
    colorIndicator: {
      flexShrink: 0,
      height: 24,
      width: 4,
      marginRight: theme.spacing(1.5),
      background,
      border:
        theme.palette.mode === 'dark'
          ? `1px solid ${theme.palette.divider}`
          : undefined,
      boxSizing: 'border-box',
    },
    sourceName: {
      // color: theme.palette.primary.main,
      color: theme.palette.text.secondary,
    },
    smallIcon: {
      position: 'relative',
      right: 2,
    },
  })
);

type Props = {
  disabled?: boolean;
  item: WorkspaceItem;
  onAddItem: (itemId: WorkspaceItem['id']) => void;
};

export const DenseWorkspaceItem = React.memo(_DenseWorkspaceItem);
function _DenseWorkspaceItem({ disabled = false, item, onAddItem }: Props) {
  const color = getItemColor(item);
  const { classes } = useStyles({
    color: isString(color)
      ? getNormalizedColor(color, {
          minLightness: 65,
          maxLightness: 65,
        })
      : '#888',
    background: generateIconBackground(color, item.isTemporary),
  });

  return (
    <ListItem
      dense
      disablePadding
      className={classes.listItem}
      secondaryAction={
        disabled ? <LockIcon fontSize="small" color="disabled" /> : null
      }
      title={`${item.sourceItem ? item.sourceItem.name + ' · ' : ''}${
        item.name
      }`}
    >
      <ListItemButton
        className={classes.listItemButton}
        disabled={disabled}
        onClick={() => onAddItem(item.id)}
      >
        <Box className={classes.colorIndicator} />

        <ListItemText
          primary={
            <span>
              {item.sourceItem && (
                <span className={classes.sourceName}>
                  {item.sourceItem.name} ·{' '}
                </span>
              )}
              {item.name}
            </span>
          }
          primaryTypographyProps={{
            noWrap: true,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}
