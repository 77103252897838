import type {
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
} from '@mui/x-data-grid-pro';
import type { MappingItem } from '@pn/core/domain/data';
import type { Filter } from '@pn/core/domain/query';
import type { UnitSystem } from '@pn/core/domain/types';
import type { IQueryFilteringMapper } from '@pn/core/mappers/query';
import { isEmpty, isNil } from 'lodash-es';

export type IDataGridQueryFilteringMapper =
  IQueryFilteringMapper<GridFilterModel>;

export const dataGridQueryFilteringMapper: IDataGridQueryFilteringMapper = {
  toDomainFiltering: (
    filterModel,
    columns: GridColDef[],
    mapping: MappingItem[],
    unitSystem: UnitSystem
  ) => {
    const filters: Filter[] = filterModel.items.map((item) => {
      const column = columns.find((column) => column.field === item.field);
      const mappingItem = mapping.find((m) => m.field === item.field);

      let value;
      switch (column?.type) {
        case 'number':
          if (isEmpty(item.value)) {
            value = undefined;
          } else {
            value = Number(item.value);
          }
          break;
        case 'boolean':
          if (isEmpty(item.value)) {
            value = undefined;
          } else {
            value = item.value.toLowerCase() === 'true';
          }
          break;
        case 'date': {
          if (isNil(item.value)) {
            value = undefined;
          } else {
            value = item.value.toISOString().slice(0, 10); // 'yyyy-MM-dd'
          }
          break;
        }
        default:
          if (isEmpty(item.value)) {
            value = undefined;
          } else {
            value = item.value;
          }
      }

      return {
        field: item.field,
        operator: item.operator,
        value,
        unitSystem:
          mappingItem?.domainType === 'SIUnit' ? unitSystem : undefined,
      };
    });

    const linkOperator = filterModel.logicOperator ?? 'and'; // MUI's link operator is undefined when only a single filter is present

    return { filters, linkOperator };
  },
  toTargetFiltering: (filters, linkOperator, columns: GridColDef[]) => {
    return {
      items: filters.map((filter, index) => {
        const column = columns.find((column) => column.field === filter.field);

        let value;
        switch (column?.type) {
          case 'singleSelect':
            if (isNil(filter.value)) {
              value = undefined;
            } else {
              value = filter.value;
            }
            break;
          case 'number':
            value = filter.value?.toString();
            break;
          case 'boolean':
            value = filter.value?.toString();
            break;
          case 'date':
            if (isNil(filter.value)) {
              value = undefined;
            } else {
              value = new Date(filter.value + 'T00:00:00Z');
            }
            break;
          default:
            if (isNil(filter.value)) {
              value = '';
            } else {
              value = filter.value.toString();
            }
        }

        return {
          id: filter.field + '-' + index,
          field: filter.field,
          operator: filter.operator,
          value,
        };
      }),
      logicOperator: linkOperator as GridLogicOperator,
    };
  },
};
