import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import {
  getItemColor,
  updateSourceItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { isEditable } from '@pn/core/permissions/editability';
import {
  useCurrentUserStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { isFreeCordova } from '@pn/core/utils/env';
import { generateIconBackground } from '@pn/services/utils/color';
import { orderBy, uniqBy } from 'lodash-es';
import React from 'react';
import { SourceItemBlock } from 'src/ui/workspace/components/SourceItemBlock';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  sourceLayerCaption: {
    position: 'absolute',
    top: -9,
    left: 9,
    background: theme.palette.background.paper,
    padding: '0px 5px',
  },
  option: {},
  optionLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  colorIndicator: {
    flexShrink: 0,
    height: 32,
    width: 4,
    marginRight: theme.spacing(1.5),
  },
  autocomplete: {
    '& .MuiInputLabel-root.Mui-disabled': {
      color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.text.primary,
      WebkitTextFillColor: theme.palette.text.primary,
    },
  },
  inputColorIndicator: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: 4,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
}));

type Props = {
  disabled: boolean;
  isSelectorEnabled: boolean;
  item: WorkspaceItem;
  sourceItem: WorkspaceItem;
};

export const SourceItemSelector = ({
  disabled,
  isSelectorEnabled,
  item,
  sourceItem,
}: Props) => {
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();
  const { allWorkspaceItems } = useWorkspaceStorage();

  const sourceItems = React.useMemo(() => {
    if (!isSelectorEnabled) {
      return []; // not used
    }

    return orderByCategory(
      uniqBy(
        allWorkspaceItems.filter(
          (item) =>
            item.itemType === 'layer' &&
            item.dataSource.type !== 'none' &&
            (isFreeCordova(user)
              ? ['wells', 'wells_usa'].includes(item.id) ||
                isEditable(item, user)
              : true)
        ),
        'name' // ignore duplicated layers
      )
    );
  }, [allWorkspaceItems, isSelectorEnabled, user]);

  if (!isSelectorEnabled) {
    return (
      <Box position="relative">
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.sourceLayerCaption}
        >
          Source layer
        </Typography>
        <SourceItemBlock disabled={disabled} item={sourceItem} />
      </Box>
    );
  }

  return (
    <Autocomplete
      fullWidth
      size="small"
      classes={{
        root: classes.autocomplete,
      }}
      autoHighlight
      disableClearable
      options={sourceItems}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={sourceItem}
      getOptionLabel={(item) => item.name}
      groupBy={(option) => option.folder}
      renderOption={(props, item) => (
        <Box component="li" className={classes.option} {...props}>
          <Box
            className={classes.colorIndicator}
            style={{
              background: generateIconBackground(getItemColor(item)),
            }}
          />
          <span className={classes.optionLabel}>{item.name}</span>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Source layer"
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            },
            input: {
              ...params.InputProps,
              style: {
                paddingLeft: 14, // why classes don't work here?
              },
              startAdornment: (
                <Box
                  className={classes.inputColorIndicator}
                  style={{
                    background: generateIconBackground(
                      getItemColor(sourceItem)
                    ),
                  }}
                />
              ),
            },
          }}
        />
      )}
      onChange={(_e, newSourceItem) =>
        workspaceActions().update(updateSourceItem(item, newSourceItem))
      }
    />
  );
};

const categoriesOrder = ['Petro Ninja', 'BOE Intel', 'Personal', 'Shared'];

function orderByCategory(items: WorkspaceItem[]) {
  return orderBy(items, (item) => {
    const index = categoriesOrder.indexOf(item.folder);
    return index === -1 ? Infinity : index;
  });
}
