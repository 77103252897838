import { LayerType } from '@pn/core/domain/layer';
import {
  useAppStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { log } from '@pn/core/utils/debug';
import { usePrevious } from '@pn/core/utils/hooks';
import { isNil, takeRight } from 'lodash-es';
import React from 'react';
import { clearWorkspaceItem } from '../clearWorkspaceItem';
import { useVisualizeWorkspaceItem } from './useVisualizeWorkspaceItem';

export function useAutoVisualizeWorkspaceItems(
  skip = false,
  tableOnly = false
) {
  const { unitSystem } = useAppStorage();
  const { workspaceItems } = useWorkspaceStorage();

  const previousUnitSystem = usePrevious(unitSystem, unitSystem);
  const previousWorkspaceItems = usePrevious(workspaceItems, []);

  const visualizeWorkspaceItem = useVisualizeWorkspaceItem(tableOnly);

  /**
   * Revisualize all workspace items with labels or SIUnit filters when units
   * change.
   */
  React.useEffect(() => {
    if (unitSystem !== previousUnitSystem) {
      workspaceItems
        .filter(({ itemType }) => ['layer', 'list'].includes(itemType))
        .forEach((item) => {
          const hasLabelLayers = item.map.layers.some(
            (layer) => layer.type === LayerType.Text
          );
          const hasFiltersWithUnits = item.query.filters.some(
            (filter) => !isNil(filter.unitSystem)
          );

          if (hasLabelLayers || hasFiltersWithUnits) {
            workspaceActions().revisualize(item.id);

            if (hasFiltersWithUnits) {
              workspaceActions().updateFiltering(
                item.id,
                item.query.filters.map((f) => ({
                  ...f,
                  unitSystem: isNil(f.unitSystem) ? undefined : unitSystem,
                })),
                item.query.filtersLinkOperator
              );
            }
          }
        });
    }
  }, [previousUnitSystem, unitSystem, workspaceItems]);

  React.useEffect(() => {
    if (skip) return;

    /**
     * Visualize workspace items in reverse order to ensure proper ordering of
     * their layers on the map.
     */
    for (let i = workspaceItems.length - 1; i >= 0; i--) {
      const item = workspaceItems[i];
      if (item.isVisible && !item.isProcessed) {
        log.info(`auto-visualize workspace item [${item.id}]`);
        visualizeWorkspaceItem(
          item,
          takeRight(workspaceItems, workspaceItems.length - 1 - i),
          true
        );
      } else if (!item.isVisible && item.isRendered) {
        log.info(`auto-hide workspace item [${item.id}]`);
        clearWorkspaceItem(item, tableOnly);
      }
    }

    previousWorkspaceItems.forEach((prevItem) => {
      if (isNil(workspaceItems.find(({ id }) => id === prevItem.id))) {
        clearWorkspaceItem(prevItem, tableOnly);
      }
    });
  }, [
    skip,
    tableOnly,
    workspaceItems,
    previousWorkspaceItems,
    visualizeWorkspaceItem,
  ]);
}
