import { isEmpty } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  separator: {
    position: 'relative',
    top: 4,
    display: 'inline-block',
    width: 1,
    height: 16,
    margin: '0 8px',
    backgroundColor: theme.palette.divider,
  },
}));

type Props = {
  secondaryText?: string;
  tertiaryText?: string;
};

export const ListSecondaryTextBlock = React.memo(_ListSecondaryTextBlock);
function _ListSecondaryTextBlock({ secondaryText, tertiaryText }: Props) {
  const { classes } = useStyles();

  if (isEmpty(secondaryText) && isEmpty(tertiaryText)) {
    return null;
  }

  return (
    <span className={classes.text}>
      {secondaryText}
      {!isEmpty(secondaryText) && !isEmpty(tertiaryText) ? (
        <span className={classes.separator} />
      ) : null}
      {tertiaryText}
    </span>
  );
}
