import { moduleItemIds } from '@pn/core/storage/workspace/pnWorkspaceItems';
import type { CheckedState } from './types';

export function getProjectCheckedState(
  projectIds: string[],
  idsInWorkspace: string[]
): CheckedState {
  const countableProjectIds = projectIds.filter(
    (id) => !moduleItemIds.includes(id)
  );
  const projectIdsInWorkspace = countableProjectIds.filter((id) =>
    idsInWorkspace.includes(id)
  );

  if (projectIdsInWorkspace.length === 0) {
    return 'unchecked';
  }

  if (projectIdsInWorkspace.length === countableProjectIds.length) {
    return 'checked';
  }

  return 'indeterminate';
}
