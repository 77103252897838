import type { Theme } from '@mui/material';
import {
  getProductionGroupings,
  type ProductionChartType,
  type ProductionItem,
} from '@pn/core/domain/production';
import type { UnitSystem } from '@pn/core/domain/types';
import {
  isGasProductionUnit,
  isLiquidProductionUnit,
  toSIUnit,
  type SIUnit,
} from '@pn/core/domain/units';
import { groupedProductionItemsMapper } from '@pn/core/mappers/production/groupedProductionItemsMapper';
import {
  ChartScale,
  type ChartSeriesStyles,
  type ProductionChartMode,
} from '@pn/services/charts/types';
import { getRangeFn } from '@pn/services/charts/utils/chartMaxValues';
import { getPointRadius } from '@pn/services/charts/utils/chartPoints';
import { getLineStyles } from '@pn/services/charts/utils/chartStyles';
import type { ChartData } from 'chart.js';
import { isNil } from 'lodash-es';
import { yAxesIds } from './chartOptions';

export function productionItemsToChartJSData(
  productionItems: ProductionItem[],
  params: {
    theme: Theme;
    productionChartType: ProductionChartType;
    unitSystem: UnitSystem;
    scale: ChartScale;
    mode: ProductionChartMode;
    seriesStyles: ChartSeriesStyles;
  }
): ChartData<'line', (number | null)[], string> {
  const groupedProductionItems =
    groupedProductionItemsMapper.toGroupedProductionItems(
      productionItems,
      params.mode
    );
  const productionGroupings = getProductionGroupings(
    productionItems,
    params.mode
  );

  const dates = groupedProductionItems.map((item) => item.date);

  const isCountShown = ['list_aggregate', 'aggregate'].includes(
    params.productionChartType
  ); // FIXME duplicated in chartOptions.ts

  return {
    labels: dates,
    datasets: productionGroupings
      .map((grouping) => {
        const data = groupedProductionItems.map(
          (item) =>
            getClampedValue(
              item[grouping.label] as SIUnit,
              params.mode,
              params.scale
            ) ?? null
        );
        // console.log('data', data);

        return {
          fill: false,
          stepped: false,
          label: grouping.label,
          data,
          ...getLineStyles(params.seriesStyles, grouping.label),
          pointRadius: getPointRadius(data),
          yAxisID: getYAxisId(grouping.label, grouping.symbol),
          symbol: grouping.symbol,
          hidden: !['Oil', 'Gas', 'Water'].includes(grouping.label),
          // parsing: false,
        };
      })
      .concat(
        isCountShown
          ? [
              {
                fill: true,
                stepped: true,
                label: 'Number of Wells',
                data: groupedProductionItems.map((item) => item.count ?? null),
                backgroundColor:
                  params.theme.palette.mode === 'light'
                    ? 'rgba(69, 90, 100, 0.2)'
                    : 'rgba(194, 159, 255, 0.2)',
                borderColor:
                  params.theme.palette.mode === 'light'
                    ? params.theme.palette.primary.dark
                    : params.theme.palette.primary.light,
                borderWidth: 2,
                borderDash: undefined,
                pointRadius: dates.map(() => 0), // hide all points
                yAxisID: 'y_count',
                symbol: '',
                hidden: false,
              },
            ]
          : []
      ),
  };
}

/**
 * Clamping is applied to chart points but not the underlying domain data.
 */
function getClampedValue(
  unit: SIUnit | undefined,
  mode: ProductionChartMode,
  scale: ChartScale
): number | undefined {
  if (isNil(unit)) return undefined;
  if (scale === ChartScale.Linear) return unit.value;

  const rangeFn = getRangeFn(unit);

  const rawValue = unit.value;
  const clampedValue =
    !isNil(rawValue) && rawValue < rangeFn(mode).min
      ? rangeFn(mode).min
      : rawValue;

  // return rawValue;
  return clampedValue;
}

function getYAxisId(
  productionGrouping: ProductionItem['productionGrouping'],
  symbol: string
): (typeof yAxesIds)[number] {
  if (productionGrouping === 'Water Cut') {
    return 'y_percent';
  } else if (productionGrouping === 'GOR') {
    return 'y_gor';
  } else if (productionGrouping === 'WGR' || productionGrouping === 'CGR') {
    return 'y_xgr';
  } else if (isLiquidProductionUnit(toSIUnit({ value: 0, symbol }))) {
    return 'y_liquid';
  } else if (isGasProductionUnit(toSIUnit({ value: 0, symbol }))) {
    return 'y_gas';
  } else {
    return 'y_liquid'; // catch-all for things like `tonnes` etc.
  }
}
