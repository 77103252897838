import type {
  GridCellParams,
  GridColDef,
  GridSingleSelectColDef,
} from '@mui/x-data-grid-pro';
import { isDropdownAttributes, type MappingItem } from '@pn/core/domain/data';
import type { GeoShape } from '@pn/core/domain/geography';
import { type DateString, type UnitSystem } from '@pn/core/domain/types';
import {
  convertSymbol,
  convertUnit,
  prettyPrintUnitSymbol,
  unitToString,
  type SIUnit,
} from '@pn/core/domain/units';
import { isNil } from 'lodash-es';
import React from 'react';
import {
  defaultDateOperators,
  defaultDropdownOperators,
  defaultNumericOperators,
  defaultStringOperators,
} from './operators';
import { renderBoolean, renderCellWithNA } from './renderCellMethods';

export function useColumns(params: {
  mapping: MappingItem[];
  fields: string[];
  unitSystem: UnitSystem;
  cellClassName?: (params: GridCellParams) => string;
}): GridColDef[] {
  return React.useMemo(() => {
    const renderableMapping = params.mapping.filter(
      (mappingItem) => !mappingItem.isNotRenderable
    );

    const columns = renderableMapping.map((mappingItem) => {
      const column: GridColDef = {
        field: mappingItem.field,
        type: domainTypeToDataGridType(
          mappingItem.domainType,
          mappingItem.domainTypeAttributes
        ),
        headerName: generateHeaderName(mappingItem, params.unitSystem),
        width: mappingItem.width ?? 150,
        sortable: !mappingItem.isNotSortable,
        cellClassName: params.cellClassName,
      };

      if (
        column.type === 'singleSelect' &&
        isDropdownAttributes(mappingItem.domainTypeAttributes)
      ) {
        (column as GridSingleSelectColDef).valueOptions =
          mappingItem.domainTypeAttributes.options;
      }

      setFilterOperators(column, column.type!);
      setValueFormatter(column, mappingItem.domainType, params.unitSystem);
      setRenderCell(column, mappingItem.domainType);

      return column;
    });

    /* Reorder columns to match the order of query.fields */
    params.fields
      .slice()
      .reverse()
      .forEach((field) => {
        const columnToBeMoved = columns.find(
          (column) => column.field === field
        );

        if (isNil(columnToBeMoved)) return; // this happens with outdated templates with fields that are no longer in the mapping

        const index = columns.findIndex((column) => column.field === field);

        columns.splice(index, 1);
        columns.unshift(columnToBeMoved);
      });

    return columns;
  }, [params.fields, params.mapping, params.unitSystem, params.cellClassName]);
}

export function domainTypeToDataGridType(
  domainType: MappingItem['domainType'],
  domainTypeAttributes: MappingItem['domainTypeAttributes']
): GridColDef['type'] {
  switch (domainType) {
    case 'string':
      if (isDropdownAttributes(domainTypeAttributes)) {
        return 'singleSelect';
      } else {
        return 'string';
      }
    case 'number':
      return 'number';
    case 'boolean':
      return 'boolean';
    case 'SIUnit':
      return 'number';
    case 'DateString':
      return 'date';
    default:
      return 'string';
  }
}

function setFilterOperators(column: GridColDef, columnType: string): void {
  switch (columnType) {
    case 'string':
      column.filterOperators = defaultStringOperators;
      break;
    case 'singleSelect':
      column.filterOperators = defaultDropdownOperators;
      break;
    case 'number':
      column.filterOperators = defaultNumericOperators;
      break;
    case 'date':
      column.filterOperators = defaultDateOperators;
      break;
    default:
    // do nothing
  }
}

/**
 * Generates a header for a given mapping item.
 * If the mapping item supports unit conversions, its symbol will be added to the header.
 */
function generateHeaderName(
  mappingItem: MappingItem,
  unitSystem: UnitSystem
): string {
  let headerName = mappingItem.label;

  if (mappingItem.domainType === 'SIUnit') {
    headerName += `\n(${prettyPrintUnitSymbol(
      convertSymbol(mappingItem.domainTypeAttributes.symbol, unitSystem)
    )})`;
  }

  return headerName;
}

function setValueFormatter(
  column: GridColDef,
  domainType: string,
  unitSystem: UnitSystem
): void {
  switch (domainType) {
    case 'number':
      column.valueFormatter = (value: number | undefined) => {
        if (isNil(value)) return undefined;
        return value.toLocaleString(undefined, {
          maximumFractionDigits: 10,
        });
      };
      break;
    case 'DateString':
      column.valueFormatter = (value: DateString | undefined) => {
        if (isNil(value)) return undefined;
        return value;
      };
      break;
    case 'GeoShape':
      column.valueFormatter = (value: GeoShape | undefined) => {
        if (isNil(value)) return undefined;
        return '[GeoShape]';
      };
      break;
    case 'SIUnit':
      column.valueFormatter = (value: SIUnit | undefined) => {
        if (isNil(value)) return undefined;
        return unitToString(convertUnit(value, unitSystem));
      };
      break;
    default:
    // do nothing
  }
}

function setRenderCell(column: GridColDef, domainType: string): void {
  switch (domainType) {
    case 'boolean':
      column.renderCell = renderBoolean;
      break;
    default:
      column.renderCell = renderCellWithNA;
  }
}
