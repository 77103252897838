import { dependencies } from '@pn/core/dependencies';
import { isExtraDrawingMode, useDrawing } from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';

export function useCursor() {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const { drawingState, drawingMode } = useDrawing();

  React.useEffect(() => {
    if (!isExtraDrawingMode(drawingMode) && !isDrawingPanelOpen) return;

    const { map } = dependencies;
    const mapboxMap = map._native;

    const onMouseMove = () => {
      if (drawingState.isCustomPanning) {
        mapboxMap.getCanvas().style.cursor = 'grabbing';
        return;
      } else if (!isNil(drawingState.vertexDragged)) {
        mapboxMap.getCanvas().style.cursor = 'pointer';
        return;
      } else if (['nw', 'se'].includes(drawingState.resizeDirection ?? '')) {
        mapboxMap.getCanvas().style.cursor = 'nwse-resize';
        return;
      } else if (['ne', 'sw'].includes(drawingState.resizeDirection ?? '')) {
        mapboxMap.getCanvas().style.cursor = 'nesw-resize';
        return;
      } else if (drawingState.isMovingFeature) {
        mapboxMap.getCanvas().style.cursor = 'grabbing';
        return;
      } else if (drawingState.isSpacebarDown) {
        mapboxMap.getCanvas().style.cursor = '';
        return;
      } else if (!isNil(drawingState.vertexHovered)) {
        mapboxMap.getCanvas().style.cursor = 'pointer';
        return;
      } else if (
        ['nw', 'se'].includes(drawingState.resizeHoverDirection ?? '')
      ) {
        mapboxMap.getCanvas().style.cursor = 'nwse-resize';
        return;
      } else if (
        ['ne', 'sw'].includes(drawingState.resizeHoverDirection ?? '')
      ) {
        mapboxMap.getCanvas().style.cursor = 'nesw-resize';
        return;
      }

      if (drawingMode === 'select') {
        mapboxMap.getCanvas().style.cursor = isEmpty(
          drawingState.featuresHovered
        )
          ? ''
          : 'move';
      } else if (drawingMode === 'text' && drawingState.isDrawing) {
        mapboxMap.getCanvas().style.cursor = 'default';
      } else if (
        drawingMode === 'text' &&
        Object.values(drawingState.featuresHovered).length === 1 &&
        Object.values(drawingState.featuresHovered)[0].type === 'text'
      ) {
        mapboxMap.getCanvas().style.cursor = 'text';
      } else {
        mapboxMap.getCanvas().style.cursor = 'crosshair';
      }
    };

    mapboxMap.on('mousemove', onMouseMove);

    return () => {
      mapboxMap.off('mousemove', onMouseMove);
    };
  }, [isDrawingPanelOpen, drawingMode, drawingState]);
}
