import type { WorkspaceItem } from '@pn/core/domain/workspace/workspaceItem';

export function getWorkspaceItemUrl(
  item: WorkspaceItem,
  baseUrl: string
): string {
  switch (item.itemType) {
    case 'layer':
      return baseUrl + '/' + encodeURIComponent(item.id);
    case 'list':
      return baseUrl + '/lists/' + encodeURIComponent(item.id);
    case 'annotation':
      return baseUrl + '/annotations/' + encodeURIComponent(item.id);
    case 'drawing':
      return baseUrl + '/drawings/' + encodeURIComponent(item.id);
  }
}
