import { dependencies } from '@pn/core/dependencies';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import { DataMultiSelectionReason } from '@pn/core/domain/query';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import {
  createDrawingMapConfig,
  createSourceItemMapConfig,
} from '@pn/core/domain/workspace';
import { ApiError, getApiErrorMessage } from '@pn/core/errors';
import { handleError } from '@pn/core/errors/handleError';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import { projectsActions, workspaceActions } from '@pn/core/storage';
import { generateTemporaryId } from '@pn/core/utils/id';
import { apiAnnotationMapper } from '@pn/services/api/annotation/apiAnnotationMapper';
import { apiListMapper } from '@pn/services/api/list/apiListMapper';
import type { DrawingState } from '@pn/services/drawing';
import assert from 'assert';
import { clone, isEmpty, isNil } from 'lodash-es';

type Return =
  | { updatedItem: WorkspaceItem; errorThrown: false }
  | { updatedItem: undefined; errorThrown: true };

export async function submitWorkspaceItem(params: {
  item: WorkspaceItem;
  generalProjectId: string | undefined;
  drawingState?: DrawingState;
}): Promise<Return> {
  const { item, generalProjectId, drawingState } = params;

  try {
    switch (item.itemType) {
      case 'layer':
        return { updatedItem: await handleLayer(item), errorThrown: false };
      case 'list':
        return {
          updatedItem: await handleList({ item, generalProjectId }),
          errorThrown: false,
        };
      case 'drawing':
        assert(drawingState, 'drawingState must be defined for drawing items');
        return {
          updatedItem: await handleDrawing({
            item,
            drawingState,
            generalProjectId,
          }),
          errorThrown: false,
        };
      default:
        throw new Error(`Unsupported item type: ${item.itemType}`);
    }
  } catch (error) {
    handleSubmitError(error, item.itemType);
    return { updatedItem: undefined, errorThrown: true };
  }
}

async function handleLayer(item: WorkspaceItem): Promise<WorkspaceItem> {
  const updatedItem = {
    ...item,
    name: item.name.trim(),
    isTemporary: false,
  };
  workspaceActions().update(updatedItem);

  return updatedItem;
}

async function handleList(params: {
  item: WorkspaceItem;
  generalProjectId: string | undefined;
}): Promise<WorkspaceItem> {
  const { item, generalProjectId } = params;
  const { apiClient } = dependencies;

  if (item.isTemporary) {
    const itemCopy: WorkspaceItem = {
      ...item,
      id: generateTemporaryId(),
      isTemporary: false,
      name: item.name.trim(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      query: !isEmpty(item.query.filters)
        ? {
            ...item.query,
            requestedIds: getMapDataItems(item).map(({ _id }) => _id),
            filters: [],
            filtersLinkOperator: 'and',
          }
        : item.query,
    };

    const response = await apiClient.request<{
      id: string;
      found: string[];
      missing: string[];
    }>({
      method: 'POST',
      url: 'v2/lists',
      payload: apiListMapper().toOriginalItem(itemCopy),
    });

    const updatedItem: WorkspaceItem = {
      ...itemCopy,
      id: response.id,
      numberOfElements: response.found.length,
      map: createSourceItemMapConfig({
        mode: 'duplicate',
        sourceItem: item,
        newItemId: response.id,
      }),
      query: {
        ...itemCopy.query,
        id: response.id,
        requestedIds: response.found,
        multiSelectionReason: DataMultiSelectionReason.List,
      },
      isProcessed: false,
    };

    workspaceActions().remove(item);
    workspaceActions().removeFromWorkspace(item.id);
    workspaceActions().unsetRequestedDataItemId(item.id);

    workspaceActions().create(updatedItem);
    workspaceActions().addToWorkspace(updatedItem.id);
    workspaceActions().select(updatedItem.id);

    workspaceActions().updateInvalidDataItemIds(response.missing);

    if (updatedItem._isTemporaryShared && !isNil(generalProjectId)) {
      projectsActions().addItem(generalProjectId, updatedItem);
    }

    return updatedItem;
  } else {
    const updatedItem = { ...item, name: item.name.trim() };
    workspaceActions().update(updatedItem);

    return updatedItem;
  }
}

async function handleDrawing(params: {
  item: WorkspaceItem;
  drawingState: DrawingState;
  generalProjectId: string | undefined;
}): Promise<WorkspaceItem> {
  const { item, drawingState, generalProjectId } = params;
  const { apiClient } = dependencies;

  const itemCopy: WorkspaceItem = {
    ...item,
    name: item.name.trim(),
    numberOfElements: Object.values(drawingState.features).filter(
      (feature) => feature.itemId === item.id
    ).length,
    map: createDrawingMapConfig({
      id: item.id,
      data: {
        type: 'drawing',
        features: drawingState.order
          .map((id) => clone(drawingState.features[id])) // cloning fixes setVisibility crashing on newly updated items
          .filter((feature) => feature.itemId === item.id),
      },
    }),
  };

  if (item.isTemporary) {
    const response = await apiClient.request<{ id: string }>({
      method: 'POST',
      url: 'v2/annotations',
      payload: apiAnnotationMapper().toOriginalItem(itemCopy),
    });

    const newId = response.id;

    const layer = itemCopy.map.layers[0];
    assert(layer.metadata, 'metadata must be defined in the drawing item');
    layer.metadata.features.forEach((feature: CanvasFeature) => {
      feature.itemId = newId;
      feature.isVisible = true;
    });

    const updatedItem: WorkspaceItem = {
      ...itemCopy,
      isTemporary: false,
      id: newId,
      dataType: newId,
      query: {
        ...itemCopy.query,
        dataType: newId,
      },
    };

    workspaceActions().remove(item);
    workspaceActions().removeFromWorkspace(item.id);

    workspaceActions().create(updatedItem);
    workspaceActions().addToWorkspace(updatedItem.id);

    if (updatedItem._isTemporaryShared && !isNil(generalProjectId)) {
      projectsActions().addItem(generalProjectId, updatedItem);
    }

    return updatedItem;
  } else {
    workspaceActions().update(itemCopy);

    return itemCopy;
  }
}

function handleSubmitError(error: unknown, itemType: string): void {
  if (error instanceof ApiError) {
    handleError({
      error,
      userFriendlyMessage: getApiErrorMessage(error),
    });
  } else {
    const type = itemType === 'drawing' ? 'annotation' : 'layer';
    handleError({
      error,
      userFriendlyMessage: `Failed to save your ${type}`,
    });
  }
}
