import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import stack_logo_grey from '@pn/ui/images/StackDX_LOGO_Grey60p_WhiteBG.svg';
import stack_logo_white from '@pn/ui/images/StackDX_LOGO_White_TransparentBG.svg';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  stackListItemButton: {
    paddingLeft: 5,
    paddingTop: 0,
    paddingBottom: 0,
  },
  categoryLogo: {
    height: 48,
  },
  sharedIcon: {
    position: 'relative',
    top: 3.5,
    marginRight: theme.spacing(1),
    fontSize: 18,
    color: theme.palette.text.disabled,
  },
}));

type Props = {
  folder: string;
  isSelected: boolean;
  onClick: (folder: string) => void;
};

export const LibraryFolder = React.memo(_LibraryFolder);
function _LibraryFolder({ folder, isSelected, onClick }: Props) {
  const { classes, theme } = useStyles();

  return (
    <ListItem disablePadding>
      <ListItemButton
        className={
          folder === 'StackDX' ? classes.stackListItemButton : undefined
        }
        selected={isSelected}
        onClick={() => onClick(folder)}
      >
        {folder === 'StackDX' ? (
          <img
            src={
              theme.palette.mode === 'light'
                ? stack_logo_grey
                : stack_logo_white
            }
            alt="StackDX"
            className={classes.categoryLogo}
          />
        ) : (
          <ListItemText primary={folder} />
        )}
      </ListItemButton>
    </ListItem>
  );
}
