import { tableActions, useTableStorage } from '@pn/core/storage';
import { debounce } from 'lodash-es';
import React from 'react';

export type TableFields = {
  defaultFields: string[];
  fields: string[];
  addFields: (fields: string[]) => void;
  removeFields: (fields: string[]) => void;
  updateFields: (fields: string[], options?: { debounced?: boolean }) => void;
  resetFields: () => void;
};

export function useTableFields(sourceLayerId: string): TableFields {
  const { defaultTableFields, tableFields } = useTableStorage();

  const defaultFields = defaultTableFields[sourceLayerId] ?? [];
  const fields = tableFields[sourceLayerId] ?? [];

  const addFields = (fields: string[]) =>
    tableActions().addTableFields(sourceLayerId, fields);
  const removeFields = (fields: string[]) =>
    tableActions().removeTableFields(sourceLayerId, fields);

  const updateFields = (
    fields: string[],
    options: { debounced?: boolean } = {}
  ) => {
    const { debounced = false } = options;

    const updateFieldsAction = () => {
      tableActions().updateTableFields(sourceLayerId, fields);
    };

    const debouncedUpdateFieldsAction = debounce(updateFieldsAction, 300);

    if (debounced) {
      debouncedUpdateFieldsAction();
    } else {
      updateFieldsAction();
    }
  };

  const resetFields = () => {
    tableActions().resetTableFields(sourceLayerId);
  };

  return {
    defaultFields,
    fields,
    addFields,
    removeFields,
    updateFields,
    resetFields,
  };
}

export function useLocalTableFields(
  _sourceLayerId: string,
  initFields: string[]
): TableFields {
  const [fields, setFields] = React.useState(initFields);

  React.useLayoutEffect(() => {
    setFields(initFields);
  }, [initFields]);

  const addFields = (fields: string[]) =>
    setFields((prevFields) => [...prevFields, ...fields]);
  const removeFields = (fields: string[]) =>
    setFields((prevFields) =>
      prevFields.filter((field) => !fields.includes(field))
    );

  const updateFields = (
    fields: string[],
    options: { debounced?: boolean } = {}
  ) => {
    const { debounced } = options;

    const updateFieldsAction = () => setFields(fields);

    const debouncedUpdateFieldsAction = debounce(updateFieldsAction, 300);

    if (debounced) {
      debouncedUpdateFieldsAction();
    } else {
      updateFieldsAction();
    }
  };

  const resetFields = () => {
    setFields(initFields);
  };

  return {
    defaultFields: initFields,
    fields,
    addFields,
    removeFields,
    updateFields,
    resetFields,
  };
}
