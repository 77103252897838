import { dependencies } from '@pn/core/dependencies';
import type { DataItemId, DataType } from '@pn/core/domain/data';
import type { User } from '@pn/core/domain/user';
import { userToProjectUser } from '@pn/core/domain/user';
import {
  WorkspaceItem,
  createSourceItemMapConfig,
} from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { projectsActions, workspaceActions } from '@pn/core/storage';
import { generateCopyName } from '@pn/core/utils/string';
import { apiLayerItemMapper } from '@pn/services/api/layer/apiLayerMapper';
import { apiListMapper } from '@pn/services/api/list/apiListMapper';

export async function duplicateWorkspaceItem(params: {
  item: WorkspaceItem;
  isInWorkspace: boolean;
  user: User;
}): Promise<{ isError?: boolean }> {
  const { item, isInWorkspace, user } = params;
  const { apiClient } = dependencies;

  const itemCopy: WorkspaceItem = {
    ...item,
    folder: 'Personal',
    name: generateCopyName(item.name),
    createdBy: userToProjectUser(user),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };

  try {
    const { id, dataType, numberOfElements, requestedIds } =
      await (async (): Promise<{
        id: string;
        dataType: DataType;
        numberOfElements: number;
        requestedIds: DataItemId[];
      }> => {
        switch (itemCopy.itemType) {
          case 'layer': {
            const response = await apiClient.request<{
              id: string;
              count: number;
            }>({
              method: 'POST',
              url: `v2/layers/${itemCopy.id}/copy`,
              payload: apiLayerItemMapper().toOriginalItem(itemCopy),
            });

            return {
              id: response.id,
              dataType: response.id,
              numberOfElements: response.count,
              requestedIds: [],
            };
          }
          case 'list': {
            const response = await apiClient.request<{
              id: string;
              found: string[];
              missing: string[];
            }>({
              method: 'POST',
              url: 'v2/lists',
              payload: apiListMapper().toOriginalItem(itemCopy),
            });

            return {
              id: response.id,
              dataType: itemCopy.dataType,
              numberOfElements: response.found.length,
              requestedIds: response.found,
            };
          }
          default:
            throw new Error(`Unsupported item type: ${itemCopy.itemType}`);
        }
      })();

    const updatedItem: WorkspaceItem = {
      ...itemCopy,
      id,
      dataType,
      numberOfElements,
      map: createSourceItemMapConfig({
        mode: 'duplicate',
        sourceItem: item,
        newItemId: id,
      }),
      query: {
        ...itemCopy.query,
        id,
        dataType,
        requestedIds,
      },
      isProcessed: false,
      isRendered: false,
    };

    workspaceActions().create(updatedItem);
    if (isInWorkspace) {
      workspaceActions().addToWorkspace(updatedItem.id);
      workspaceActions().select(updatedItem.id);
    }
    projectsActions().unselect();

    return {};
  } catch (error) {
    handleError({
      error,
      userFriendlyMessage: 'Failed to duplicate this layer',
    });

    return { isError: true };
  }
}
