import { toGeoPoint, type GeoPoint } from '@pn/core/domain/geography';
import {
  useAutoCenterMapOnSelectedItem,
  useMapMode,
} from '@pn/core/operations/mapInteractions';
import { useRouter } from '@pn/core/operations/router';
import { useWorkspaceLogic } from '@pn/core/operations/workspace';
import { useAccess } from '@pn/core/permissions/access';
import { currentUserActions } from '@pn/core/storage';
import { isEmbedded } from '@pn/core/utils/embedded';
import { isTouchDevice } from '@pn/core/utils/env';
import { isExtraDrawingMode, useDrawing } from '@pn/services/drawing';
import { useDrawingLogic } from '@pn/services/drawing/hooks';
import { getDirectionsUrl } from '@pn/services/map/google/directions';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  map,
  notify,
  useAuthenticationService,
} from 'src/application/externalDependencies';
import { useInitFromUrl } from 'src/ui/effects/useInitFromUrl';
import { useInitProjectFromUrl } from 'src/ui/effects/useInitProjectFromUrl';
import { openInNewTab } from 'src/ui/main-panel/utils';
import { MapboxPopup } from 'src/ui/map/components/MapboxPopup';
import { useMapBoxSelection } from 'src/ui/map/hooks/useMapBoxSelection';
import { useMapClicks } from 'src/ui/map/hooks/useMapClicks';
import { useVectorMapClicks } from 'src/ui/map/hooks/useVectorMapClicks';

export const MapEffects = () => {
  const { pathname } = useRouter();
  const { smScreen } = useScreenSize();

  // const { workspaceItems } = useWorkspaceStorage();
  // const temporaryDrawingItem = React.useMemo(
  //   () => workspaceItems.find((i) => i.itemType === 'drawing' && i.isTemporary),
  //   [workspaceItems]
  // );

  const access = useAccess();
  const { isAuthenticated } = useAuthenticationService();

  const { isDrawingPanelOpen, liveItemOpened } = useWorkspaceItemPanel();
  const { drawingMode } = useDrawing();

  const popUpRef = React.useRef(
    new mapboxgl.Popup({
      closeOnClick: true,
      anchor: 'left',
      closeButton: false,
    })
  );

  useWorkspaceLogic({
    hideCrosshair: isDrawingPanelOpen || isExtraDrawingMode(drawingMode),
  });
  useDrawingLogic(liveItemOpened?.id ?? '');

  useMapMode();
  useInitFromUrl();
  useInitProjectFromUrl();
  useAutoCenterMapOnSelectedItem();
  useVectorMapClicks();
  useMapClicks();
  useMapBoxSelection();
  // useInspectLayerProperties('wells:wells:0'); /* debug tool */

  /**
   * Turn on location tracking automatically on mobile devices.
   * Won't trigger when following a well URL until Quick Info is closed.
   */
  const hasTriggeredGeolocate = React.useRef(false);
  React.useEffect(() => {
    if (
      !hasTriggeredGeolocate.current &&
      pathname === '/' &&
      isAuthenticated &&
      smScreen &&
      isTouchDevice()
    ) {
      map._native.geolocate.trigger();
      hasTriggeredGeolocate.current = true;
    }
  }, [pathname, smScreen, isAuthenticated]);

  React.useEffect(() => {
    if (isEmbedded() || isDrawingPanelOpen) return;

    const setLocation = (geoPoint: GeoPoint) => {
      if (access('homeLocation').notify().denied()) return;

      currentUserActions().updateHomeLocation(geoPoint);
      notify('Home location updated!');

      popUpRef.current.remove();
    };

    const getDirections = (geoPoint: GeoPoint) => {
      openInNewTab(getDirectionsUrl(geoPoint));

      popUpRef.current.remove();
    };

    function handleContextMenu(
      event: mapboxgl.MapMouseEvent & mapboxgl.EventData
    ) {
      const popupNode = document.createElement('div');
      const root = createRoot(popupNode);

      root.render(
        <MapboxPopup
          geoPoint={toGeoPoint(event.lngLat.lat, event.lngLat.lng)}
          onSetAsHomeLocation={setLocation}
          onGetDirections={getDirections}
        />
      );

      popUpRef.current
        .setLngLat(event.lngLat)
        .setDOMContent(popupNode)
        .addTo(map._native);

      /* Cleanup function to unmount the component when the popup is removed */
      popUpRef.current.on('close', () => {
        root.unmount();
      });
    }

    map._native.on('contextmenu', handleContextMenu);

    return () => {
      map._native.off('contextmenu', handleContextMenu);
    };
  }, [isDrawingPanelOpen, access]);

  return null;
};
