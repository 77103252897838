import type { IProjectsProvider } from '@pn/core/providers/project/ports';
import { pnApiClient } from '../pnApiClient';
import { apiProjectMapper } from './apiProjectMapper';
import type { ApiProject } from './types';

export const fetchCurrentUserProjects = async () => {
  const apiProjects = await pnApiClient.request<ApiProject[]>({
    url: 'v2/projects',
  });

  const projects = apiProjects.map(apiProjectMapper.toDomainProject);

  return projects;
};

export const apiProjectsProvider: IProjectsProvider = {
  getCurrentUserProjects: fetchCurrentUserProjects,
};
