import { Box, Button } from '@mui/material';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { useLibrary } from '@pn/ui/workspace/LibraryProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  buttonContainer: {
    gridArea: 'button-container',
    display: 'flex',
    alignItems: 'center',
    height: 70,
    padding: theme.spacing(1.5),
    background: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  button: {
    textTransform: 'none',
  },
}));

export const WorkspaceLibraryControls = () => {
  const { smScreen } = useScreenSize();
  const { classes } = useStyles();

  const { isLibraryOpen, openLibrary, closeLibrary } = useLibrary();

  if (smScreen) return null;

  return (
    <Box className={classes.buttonContainer}>
      {isLibraryOpen ? (
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={closeLibrary}
        >
          Close Library
        </Button>
      ) : (
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={openLibrary}
        >
          Open Library
        </Button>
      )}
    </Box>
  );
};
