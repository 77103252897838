import { useDrawing } from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { isNil } from 'lodash-es';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export function useHistory() {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const { drawingState, historyManager, redraw } = useDrawing();

  /**
   * Reset history every time drawing panel is opened.
   */
  React.useEffect(() => {
    if (!isDrawingPanelOpen) return;

    historyManager.reinit(drawingState);
  }, [isDrawingPanelOpen, historyManager, drawingState]);

  useHotkeys('ctrl+z', (e) => {
    e.preventDefault();

    const snapshot = historyManager.undo();
    if (!isNil(snapshot)) {
      snapshot.featuresSelected = {};
      snapshot.featuresHovered = {};
      Object.assign(drawingState, snapshot);
      redraw();
    }
  });

  useHotkeys(['ctrl+shift+z', 'ctrl+y'], (e) => {
    e.preventDefault();

    const snapshot = historyManager.redo();
    if (!isNil(snapshot)) {
      snapshot.featuresSelected = {};
      snapshot.featuresHovered = {};
      Object.assign(drawingState, snapshot);
      redraw();
    }
  });
}
