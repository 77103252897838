import { Box, Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ChartIcon } from './ChartIcon';
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles<{ outline: boolean; isError?: boolean }>()(
  (theme, { outline, isError }) => ({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    paper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      backgroundColor: outline
        ? theme.palette.background.paper
        : theme.palette.background.default,
      border: outline ? undefined : 'none',
    },
    iconContainer: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      fontSize: 64,
      color: isError
        ? theme.palette.error.light
        : theme.palette.action.disabled,
    },
  })
);

type Props = {
  outline?: boolean;
  text: string;
  isError?: boolean;
};

export const ChartEmptyState = ({
  outline = true,
  text,
  isError = false,
}: Props) => {
  const { classes } = useStyles({ outline, isError });

  return (
    <Box className={classes.container}>
      <Paper variant="outlined" className={classes.paper}>
        <Box className={classes.iconContainer}>
          {isError ? (
            <ErrorIcon className={classes.icon} />
          ) : (
            <ChartIcon className={classes.icon} />
          )}
          <Box mt={1} />
          <Typography color="textSecondary">{text}</Typography>
        </Box>
      </Paper>
    </Box>
  );
};
