import type { Project } from '@pn/core/domain/project';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { centerMapOnWorkspaceItem } from '@pn/core/operations/mapInteractions';
import { useRouter } from '@pn/core/operations/router';
import { useStackProjects } from '@pn/core/operations/stackdx/stackProjects';
import {
  projectsActions,
  useProjectsStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { pnWorkspaceItems } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { apiStackProjectDetailsMapper } from '@pn/services/api/stackdx/apiStackProjectDetailsMapper';
import { getStackProjectDetails } from '@pn/services/api/stackdx/apiStackProjectDetailsProvider';
import { apiStackWorkspaceItemToWorkspaceItemMapper } from '@pn/services/api/stackdx/apiStackWorkspaceItemMapper';
import { drop, isEmpty, isNil, last } from 'lodash-es';
import React from 'react';
import { notificationService } from 'src/application/externalDependencies';

const STACK_NOTIFICATION_ID = 'init-stack-project-from-url';

export function useInitProjectFromUrl() {
  const {
    query: { projectId: queryProjectId },
  } = useRouter();

  const { projects } = useProjectsStorage();
  const { workspaceItemSelected } = useWorkspaceStorage();
  const { stackSourceItems } = useStackProjects();

  const [triggerCenter, setTriggerCenter] = React.useState(false);

  const hasRun = React.useRef(false);
  React.useEffect(() => {
    if (hasRun.current || isNil(queryProjectId)) return;

    function visualizeProject(project: Project, items?: WorkspaceItem[]): void {
      projectsActions().add(project);
      projectsActions().select(project.id);

      if (!isNil(items)) workspaceActions().add(items);
      workspaceActions().setWorkspace(project.workspaceItemIds);
      workspaceActions().select(last(project.workspaceItemIds));

      setTriggerCenter(true);
    }

    if (queryProjectId.startsWith('stackdx|')) {
      if (isNil(notificationService.get(STACK_NOTIFICATION_ID))) {
        notificationService.displayPersistentNotification(
          'Loading Stack project...',
          {
            id: STACK_NOTIFICATION_ID,
            showSpinner: true,
          }
        );
      }

      if (isEmpty(stackSourceItems)) return;

      hasRun.current = true;

      (async () => {
        try {
          const stackProjectDetails = await getStackProjectDetails(
            queryProjectId.split('|')[1]
          );

          const project =
            apiStackProjectDetailsMapper.toDomainProject(stackProjectDetails);

          const items = project.workspaceItemIds.reduce<WorkspaceItem[]>(
            (acc, id) => {
              const sourceItem = [
                ...stackSourceItems,
                ...pnWorkspaceItems,
              ].find(
                (item) => item.id === drop(id.split('|')).join('|') // HACK why the ID mismatch in the first place?
              );
              const list =
                apiStackWorkspaceItemToWorkspaceItemMapper().toWorkspaceItem(
                  stackProjectDetails,
                  sourceItem
                );
              acc.push(list);
              return acc;
            },
            []
          );

          visualizeProject(project, items);
        } catch (error) {
          handleError({ error });
        }

        notificationService.removePersistentNotification(STACK_NOTIFICATION_ID);
      })();

      return;
    } else {
      if (projects.every(({ origin }) => origin !== 'pn')) return;

      hasRun.current = true;

      const project = projects.find((project) => project.id === queryProjectId);
      if (!isNil(project)) {
        visualizeProject(project);
      } else {
        notificationService.notify(
          'Project does not exist or is not accessible',
          'warning'
        );
      }
    }
  }, [queryProjectId, stackSourceItems, projects]);

  React.useEffect(() => {
    if (triggerCenter && workspaceItemSelected?.isRendered) {
      setTriggerCenter(false);
      centerMapOnWorkspaceItem(workspaceItemSelected);
    }
  }, [triggerCenter, workspaceItemSelected]);
}
