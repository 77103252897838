import type { Project } from '@pn/core/domain/project';
import React from 'react';

type ProjectDialogContextType = {
  isProjectDialogOpen: boolean;
  projectToEdit: Project | undefined;
  openProjectDialog: (project: Project | undefined) => void;
  closeProjectDialog: () => void;
};

const ProjectDialogContext = React.createContext(
  {} as ProjectDialogContextType
);

export const useProjectDialog = () => React.useContext(ProjectDialogContext);

type ProjectDialogProviderProps = {
  children: React.ReactNode;
};

export const ProjectDialogProvider = ({
  children,
}: ProjectDialogProviderProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [projectToEdit, setProjectToEdit] = React.useState<
    Project | undefined
  >();

  const openProjectDialog = React.useCallback(
    (project: Project | undefined) => {
      setProjectToEdit(project);
      setIsOpen(true);
    },
    []
  );
  const closeProjectDialog = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <ProjectDialogContext.Provider
      value={{
        isProjectDialogOpen: isOpen,
        projectToEdit,
        openProjectDialog,
        closeProjectDialog,
      }}
    >
      {children}
    </ProjectDialogContext.Provider>
  );
};
