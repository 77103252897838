import type { Point } from '@pn/core/domain/point';
import { round } from 'lodash-es';
import {
  BezierInterpolation,
  Point as BPoint,
} from 'rulyotano.math.interpolation.bezier';

export function pathToPoints(pathData: string, decimals = 1): Point[] {
  const commands = pathData.match(/[a-zA-Z][^a-zA-Z]*/g);
  if (!commands) return [];

  const points: Point[] = [];

  for (const command of commands) {
    const type = command[0];
    const coords = command
      .slice(1)
      .trim()
      .split(/[\s,]+/)
      .map((n) => round(Number(n), decimals));

    switch (type) {
      case 'M':
      case 'L':
        for (let i = 0; i < coords.length; i += 2) {
          points.push({ x: coords[i], y: coords[i + 1] });
        }
        break;
      case 'C':
        for (let i = 4; i < coords.length; i += 6) {
          points.push({ x: coords[i], y: coords[i + 1] });
        }
        break;
      case 'Q':
        for (let i = 2; i < coords.length; i += 4) {
          points.push({ x: coords[i], y: coords[i + 1] });
        }
        break;
      case 'S':
      case 'T':
        for (let i = 2; i < coords.length; i += 4) {
          points.push({ x: coords[i], y: coords[i + 1] });
        }
        break;
      default:
        console.error(`Unsupported path command: ${type}`);
        break;
    }
  }

  return points;
}

export function pointsToCurvedPath(points: Point[]): string {
  if (points.length === 1)
    return `M${points[0].x} ${points[0].y} L${points[0].x} ${points[0].y}`;
  if (points.length === 2)
    return `M${points[0].x} ${points[0].y} L${points[1].x} ${points[1].y}`;

  const pointsArray = points.map((point) => new BPoint(point.x, point.y));
  const result = BezierInterpolation.pointsToBezierCurves(
    pointsArray,
    false,
    1
  ); // .toPath() is broken

  const pathCommands = result.segments.map((curve, index) => {
    if (index === 0) {
      return `M${curve.start.x} ${curve.start.y} C${curve.firstControl.x} ${curve.firstControl.y},${curve.secondControl.x} ${curve.secondControl.y},${curve.end.x} ${curve.end.y}`;
    } else {
      return `C${curve.firstControl.x} ${curve.firstControl.y},${curve.secondControl.x} ${curve.secondControl.y},${curve.end.x} ${curve.end.y}`;
    }
  });

  return pathCommands.join(' ');
}
