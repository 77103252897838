import { Box, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

type EmptyStateVariant = 'standard' | 'filled';

const useStyles = makeStyles<{ variant: EmptyStateVariant }>()(
  (theme, { variant }) => ({
    container: {
      padding: theme.spacing(4),
      background: variant === 'filled' ? theme.palette.action.hover : undefined,
      borderRadius: theme.shape.borderRadius,
      textAlign: 'center',
    },
    icon: {
      color: theme.palette.action.disabled,
      fontSize: 48,
    },
  })
);

type Props = {
  variant?: EmptyStateVariant;
  text: string | React.ReactNode;
  icon: React.ComponentType<any>;
};

export function EmptyStateWithIcon({
  variant = 'standard',
  text,
  icon: Icon,
}: Props) {
  const { classes } = useStyles({ variant });

  return (
    <Box className={classes.container}>
      <Icon className={classes.icon} />
      <Typography color="textSecondary">{text}</Typography>
    </Box>
  );
}
