import { DragOverlay, useDndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { Box } from '@mui/material';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { useWorkspaceStorage } from '@pn/core/storage';
import { isNil } from 'lodash-es';
import React from 'react';
import { SortableWorkspaceItem } from 'src/ui/workspace/SortableWorkspaceItem';
import { WorkspaceItemComponent } from 'src/ui/workspace/WorkspaceItem';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  content: {
    gridArea: 'workspace-content',
    overflowY: 'auto',
  },
}));

type Props = {
  drawingIds: string[];
  otherIds: string[];
};

export const WorkspaceContent = React.memo(
  ({ drawingIds, otherIds }: Props) => {
    const { classes } = useStyles();
    const { active } = useDndContext();

    const { workspaceItems } = useWorkspaceStorage();

    const { drawingItems, otherItems } = React.useMemo(() => {
      const drawingItems: Record<string, WorkspaceItem> = {};
      const otherItems: Record<string, WorkspaceItem> = {};

      workspaceItems.forEach((item) => {
        if (item.itemType === 'drawing') {
          drawingItems[item.id] = item;
        } else {
          otherItems[item.id] = item;
        }
      });

      return { drawingItems, otherItems };
    }, [workspaceItems]);

    const activeItem = React.useMemo(
      () => workspaceItems.find((item) => item.id === active?.id),
      [active, workspaceItems]
    );

    return (
      <Box className={classes.content}>
        <SortableContext id="drawings" items={drawingIds}>
          {drawingIds
            .map((id) => {
              const item = drawingItems[id];
              if (isNil(item)) {
                return undefined;
              } else {
                return <SortableWorkspaceItem key={id} item={item} />;
              }
            })
            .filter(Boolean)}
        </SortableContext>
        <SortableContext id="others" items={otherIds}>
          {otherIds
            .map((id) => {
              const item = otherItems[id];
              if (isNil(item)) {
                return undefined;
              } else {
                return <SortableWorkspaceItem key={id} item={item} />;
              }
            })
            .filter(Boolean)}
        </SortableContext>
        <DragOverlay dropAnimation={{ duration: 200, easing: 'ease' }}>
          {!isNil(activeItem) ? (
            <WorkspaceItemComponent item={activeItem} isDragging />
          ) : null}
        </DragOverlay>
      </Box>
    );
  }
);
