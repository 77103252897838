import { isNil } from 'lodash-es';
import React from 'react';

type State = {
  visualizedSourceLayers: Record<string, boolean>;
};

type Action = {
  type: 'ADD_SOURCE_LAYER' | 'REMOVE_SOURCE_LAYER';
  payload: {
    layerId: string;
  };
};

type Dispatch = (action: Action) => void;

type ExtraDataProviderProps = {
  children: React.ReactNode;
};

const initialState: State = {
  visualizedSourceLayers: {},
};

const ExtraDataContext = React.createContext<{
  state: State;
  dispatch: Dispatch;
}>({
  state: initialState,
  dispatch: () => null,
});

export const useExtraDataContext = () => {
  const context = React.useContext(ExtraDataContext);

  if (isNil(context)) {
    throw new Error(
      'useExtraDataContext must be used within a ExtraDataProvider'
    );
  }

  return context;
};

const extraDataReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'ADD_SOURCE_LAYER': {
      return {
        ...state,
        visualizedSourceLayers: {
          ...state.visualizedSourceLayers,
          [action.payload.layerId]: true,
        },
      };
    }

    case 'REMOVE_SOURCE_LAYER': {
      const { [action.payload.layerId]: _, ...rest } =
        state.visualizedSourceLayers;

      return {
        ...state,
        visualizedSourceLayers: rest,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const ExtraDataProvider = ({ children }: ExtraDataProviderProps) => {
  const [state, dispatch] = React.useReducer(extraDataReducer, initialState);

  return (
    <ExtraDataContext.Provider value={{ state, dispatch }}>
      {children}
    </ExtraDataContext.Provider>
  );
};
