import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import {
  useProjectsStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { useConfirmationDialog } from '@pn/ui/context-components/ConfirmationDialog';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { DeleteConfirmationDialogContent } from 'src/ui/workspace/components/DeleteConfirmationDialogContent';

type Props = {
  item: WorkspaceItem;
  disabled: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onRename: () => void;
  onDuplicate: () => Promise<void>;
};

export const WorkspaceItemPanelMenu = ({
  item,
  disabled,
  anchorEl,
  onClose,
  onRename,
  onDuplicate,
}: Props) => {
  const { allWorkspaceItems } = useWorkspaceStorage();
  const { projects } = useProjectsStorage();

  const { closeWorkspaceItemPanel } = useWorkspaceItemPanel();
  const { handleOpenConfirmationDialog } = useConfirmationDialog();

  const handleRename = () => {
    onRename();
    onClose();
  };

  const handleDelete = () => {
    workspaceActions().remove(item);

    onClose();
    closeWorkspaceItemPanel(true);
  };

  const handleDeleteWithConfirmation = () => {
    if (item.isTemporary) {
      return handleDelete();
    }

    onClose();

    const projectsWithItem = projects.filter((project) =>
      project.workspaceItemIds.includes(item.id)
    );
    const childrenItems =
      item.itemType !== 'layer'
        ? []
        : allWorkspaceItems.filter(
            (workspaceItem) => workspaceItem.sourceItem?.id === item.id
          );

    handleOpenConfirmationDialog({
      title: 'Delete layer?',
      text: (
        <DeleteConfirmationDialogContent
          itemName={item.name}
          projectsWithItem={projectsWithItem}
          childrenItems={childrenItems}
        />
      ),
      action: () => {
        handleDelete();
      },
    });
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      slotProps={{
        paper: {
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        },
      }}
    >
      <MenuItem disabled={disabled} onClick={handleRename}>
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Rename</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={item.isTemporary || item.isGlobal}
        onClick={onDuplicate}
      >
        <ListItemIcon>
          <CopyAllIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Duplicate</ListItemText>
      </MenuItem>
      <MenuItem disabled={disabled} onClick={handleDeleteWithConfirmation}>
        <ListItemIcon>
          <DeleteForeverIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </Menu>
  );
};
