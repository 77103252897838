import { dependencies } from '@pn/core/dependencies';
import {
  getPointsApproximationFromDataItems,
  type DataItem,
} from '@pn/core/domain/data';
import {
  geometryToGeoShape,
  getBoundingBox,
  getCenterPoint,
} from '@pn/core/domain/geography';
import { isAnnotationSource } from '@pn/core/domain/layer';
import { DataSelectionReason } from '@pn/core/domain/query';
import {
  getDataItemSelected,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { useRouter } from '@pn/core/operations/router';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import { useWorkspaceStorage } from '@pn/core/storage';
import {
  REFERENCE_PT,
  REFERENCE_ZOOM,
  getCanvasBoundingBox,
  toGeoBoundingBox,
} from '@pn/services/drawing';
import assert from 'assert';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';

export const centerMapOnWorkspaceItem = (item: WorkspaceItem | undefined) => {
  const { map } = dependencies;

  if (isNil(item)) return;

  if (
    item.itemType === 'annotation' &&
    isAnnotationSource(item.map.layers[0].source)
  ) {
    const bbox = getBoundingBox(
      item.map.layers[0].source.data.features.map(({ geometry }) =>
        geometryToGeoShape(geometry)
      )
    );
    map.fitToBoundingBox(bbox);
    return;
  }

  if (item.itemType === 'drawing') {
    const layer = item.map.layers[0];
    assert(layer.metadata?.type === 'drawing', 'Invalid drawing layer');
    if (isEmpty(layer.metadata.features)) return;

    const canvasBbox = getCanvasBoundingBox(layer.metadata.features);
    const bbox = toGeoBoundingBox(canvasBbox, REFERENCE_PT, REFERENCE_ZOOM);
    map.fitToBoundingBox(bbox, { maxZoom: 18 });

    return;
  }

  const dataItemSelected = getDataItemSelected(item);

  if (!isNil(dataItemSelected) && !isNil(dataItemSelected.geoShape)) {
    centerMapOnDataItems([dataItemSelected]);
  } else {
    centerMapOnDataItems(getMapDataItems(item));
  }
};

export function centerMapOnDataItems(dataItems: DataItem[]): void {
  const { map } = dependencies;

  if (isEmpty(dataItems)) {
    // console.warn('no data items to center on');
    return;
  }

  const points = getPointsApproximationFromDataItems(dataItems);
  map.fitToBoundingBox(getBoundingBox(points), {
    padding: 50,
    maxZoom: 11.75,
  });
}

export function useAutoCenterMapOnSelectedItem() {
  const {
    query: { zoom: queryZoom },
  } = useRouter();

  const { dataItemSelected, dataItemRequested } = useWorkspaceStorage();

  React.useEffect(() => {
    const { map } = dependencies;

    if (
      dataItemSelected?.geoShape &&
      !isNil(dataItemRequested.reason) &&
      dataItemRequested.reason !== DataSelectionReason.MapClick &&
      dataItemRequested.reason !== DataSelectionReason.Related &&
      dataItemRequested.reason !== DataSelectionReason.Search
    ) {
      if (
        dataItemRequested.reason === DataSelectionReason.Url &&
        dataItemSelected.geoShape.type !== 'Point'
      ) {
        map.fitToBoundingBox(getBoundingBox([dataItemSelected.geoShape]), {
          padding: 50,
          maxZoom: 11.75,
        });
      } else {
        const zoom = isNil(queryZoom) ? undefined : Number(queryZoom);
        map.moveTo(getCenterPoint(dataItemSelected.geoShape), zoom);
      }
    }
  }, [dataItemSelected, dataItemRequested.reason, queryZoom]);
}
