import { generateTemporaryId } from '../utils/id';
import type { ISODateTimeString } from './types';
import type { User } from './user';

export type Channel = {
  id: string;
  name: string;
  createdAt?: ISODateTimeString;
  userIds: User['id'][];
  isProtected: boolean;
};

export function isChannel(arg: unknown): arg is Channel {
  return (
    typeof arg === 'object' &&
    arg !== null &&
    'id' in arg &&
    'name' in arg &&
    'userIds' in arg &&
    'isProtected' in arg
  );
}

export function isArrayOfChannels(arg: unknown): arg is Channel[] {
  return Array.isArray(arg) && arg.every(isChannel);
}

export type ChannelForm = {
  name: string;
  userIds: User['id'][];
};

export function createChannel(channelForm: ChannelForm): Channel {
  return {
    id: generateTemporaryId(),
    createdAt: new Date().toISOString(),
    isProtected: false,
    ...channelForm,
  };
}
