import { nullToUndefined } from '@pn/core/utils/logic';
import { isNil } from 'lodash-es';
import React from 'react';
import type {
  ChartScale,
  ChartSeries,
  ChartSeriesStyles,
  DisplayMode,
  ProductionChartMode,
} from '../types';

export function getInitialChartControls() {
  const lsSeries = localStorage.getItem('charts-production-series');
  const lsSeriesStyles = localStorage.getItem(
    'charts-production-series-styles'
  );

  return {
    initDisplayMode: nullToUndefined(
      localStorage.getItem('production-display-mode')
    ),
    initScale: nullToUndefined(localStorage.getItem('charts-production-scale')),
    initMode: nullToUndefined(localStorage.getItem('charts-production-mode')),
    initSeries: !isNil(lsSeries)
      ? (JSON.parse(lsSeries) as ChartSeries)
      : undefined,
    initSeriesStyles: !isNil(lsSeriesStyles)
      ? (JSON.parse(lsSeriesStyles) as ChartSeriesStyles)
      : undefined,
  };
}

export function useStoreControlsToLocalStorage(controls: {
  displayMode: DisplayMode;
  scale: ChartScale;
  mode: ProductionChartMode;
  series: ChartSeries;
  seriesStyles: ChartSeriesStyles;
}) {
  React.useEffect(() => {
    if (controls.displayMode === 'chart' || controls.displayMode === 'table') {
      localStorage.setItem('production-display-mode', controls.displayMode);
    }
  }, [controls.displayMode]);

  React.useEffect(() => {
    localStorage.setItem('charts-production-scale', controls.scale);
  }, [controls.scale]);

  React.useEffect(() => {
    localStorage.setItem('charts-production-mode', controls.mode);
  }, [controls.mode]);

  React.useEffect(() => {
    const lsSeries = localStorage.getItem('charts-production-series');
    const lsSeriesObject = !isNil(lsSeries) ? JSON.parse(lsSeries) : {};
    const combinedSeries = {
      ...lsSeriesObject,
      ...controls.series,
    };

    localStorage.setItem(
      'charts-production-series',
      JSON.stringify(combinedSeries)
    );
  }, [controls.series]);

  React.useEffect(() => {
    localStorage.setItem(
      'charts-production-series-styles',
      JSON.stringify(controls.seriesStyles)
    );
  }, [controls.seriesStyles]);
}
