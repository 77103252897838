import CancelIcon from '@mui/icons-material/Cancel';
import PaletteIcon from '@mui/icons-material/Palette';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { Box, Fab, Tooltip } from '@mui/material';
import { createDrawing } from '@pn/core/operations/workspace/crud/createDrawing';
import { useAccess } from '@pn/core/permissions/access';
import { useCurrentUserStorage, useWorkspaceStorage } from '@pn/core/storage';
import { isEmbedded } from '@pn/core/utils/embedded';
import { isFreeCordova } from '@pn/core/utils/env';
import { useDrawing } from '@pn/services/drawing';
import { useAnnotations } from '@pn/ui/annotations/AnnotationProvider';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { CircleSelectIcon, PolygonSelectIcon } from '@pn/ui/icons';
import { useLibrary } from '@pn/ui/workspace/LibraryProvider';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'assert';
import { useHotkeys } from 'react-hotkeys-hook';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    '@media print': {
      display: 'none',
    },
  },
  badge: {
    zIndex: theme.zIndex.fab,
  },
}));

export const MapButtons = () => {
  const { classes } = useStyles();
  const { smScreen } = useScreenSize();

  const access = useAccess();
  const isAccessDenied = access('annotations').denied();

  const { user } = useCurrentUserStorage();
  const { allWorkspaceItems } = useWorkspaceStorage();

  const { isAnnotationInterfaceOpen, drawMode, setDrawMode } = useAnnotations();
  const { drawingMode, setDrawingMode, redraw, reset } = useDrawing();
  const { closeLibrary } = useLibrary();
  const { isDrawingPanelOpen, openWorkspaceItemPanel } =
    useWorkspaceItemPanel();

  const isPolygonToolActive =
    !isAnnotationInterfaceOpen && drawMode === 'draw_annotation_polygon';
  const isCircleToolActive =
    !isAnnotationInterfaceOpen && drawMode === 'draw_annotation_circle';

  useHotkeys(
    'esc',
    () => {
      if (isPolygonToolActive || isCircleToolActive) {
        setDrawMode('simple_select');
      }
    },
    [isPolygonToolActive, isCircleToolActive]
  );

  const actions = [
    {
      tooltip: isPolygonToolActive ? 'Cancel' : 'Select (Polygon)',
      color: isPolygonToolActive
        ? ('secondary' as const)
        : ('default' as const),
      disabled: isAccessDenied,
      ariaLabel: 'polygon selection',
      onClick: () =>
        isPolygonToolActive
          ? setDrawMode('simple_select')
          : setDrawMode('draw_annotation_polygon'),
      icon: isPolygonToolActive ? <CancelIcon /> : <PolygonSelectIcon />,
    },
    {
      tooltip: isCircleToolActive ? 'Cancel' : 'Select (Circle)',
      color: isCircleToolActive ? ('secondary' as const) : ('default' as const),
      disabled: isAccessDenied,
      ariaLabel: 'circle selection',
      onClick: () =>
        isCircleToolActive
          ? setDrawMode('simple_select')
          : setDrawMode('draw_annotation_circle'),
      icon: isCircleToolActive ? <CancelIcon /> : <CircleSelectIcon />,
    },
  ];

  actions.push({
    tooltip: 'Distance',
    color:
      drawingMode === 'distance'
        ? ('secondary' as const)
        : ('default' as const),
    disabled: false,
    ariaLabel: 'measure distance',
    onClick: () => {
      if (drawingMode === 'distance') {
        setDrawingMode('select');

        /* Reset all temporary drawings on canvas */
        reset('');
        redraw();
      } else {
        setDrawingMode('distance');
      }
    },
    icon: <SquareFootIcon />,
  });

  const handleCreate = () => {
    if (access('drawings').notify().denied()) return;
    assert(user, 'User must be defined to create a drawing');

    const newItem = createDrawing(allWorkspaceItems, user);

    openWorkspaceItemPanel({ item: newItem });
    closeLibrary();
  };

  if (smScreen || isEmbedded() || isFreeCordova(user)) return null;

  return (
    <Box className={classes.container}>
      <Tooltip title="Draw" placement="left">
        <Box component="span">
          <Fab
            aria-label="annotate the map"
            size="small"
            color="default"
            disabled={isDrawingPanelOpen}
            onClick={handleCreate}
          >
            <PaletteIcon />
          </Fab>
        </Box>
      </Tooltip>
      {actions.map((action) => (
        <Tooltip key={action.ariaLabel} title={action.tooltip} placement="left">
          <Box component="span">
            <Fab
              aria-label={action.ariaLabel}
              size="small"
              color={action.color}
              disabled={action.disabled}
              onClick={action.onClick}
            >
              {action.icon}
            </Fab>
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
};
