import { dependencies } from '@pn/core/dependencies';
import type { Enterprise } from '@pn/core/domain/enterprise';
import { handleError } from '@pn/core/errors/handleError';
import {
  channelsActions,
  currentEnterpriseActions,
  enterpriseUsersActions,
  useCurrentUserStorage,
} from '@pn/core/storage';
import { apiChannelMapper } from '@pn/services/api/channel/apiChannelMapper';
import { apiEnterpriseMapper } from '@pn/services/api/enterprise/apiEnterpriseMapper';
import { apiUserMapper } from '@pn/services/api/user/apiUserMapper';
import { isNil } from 'lodash-es';
import React from 'react';

async function getCurrentEnterpriseCombined(id: Enterprise['id']) {
  try {
    currentEnterpriseActions().request();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await dependencies.apiClient.request<any>({
      url: `v1/enterprises/${id}`,
    });

    const enterprise = apiEnterpriseMapper.toDomainEnterprise(response);
    const channels = response.channels.map(apiChannelMapper.toDomainChannel);
    const users = response.users.map(apiUserMapper.toDomainUser);

    currentEnterpriseActions().receive(enterprise);
    channelsActions().receive(channels);
    enterpriseUsersActions().receive(users);
  } catch (error) {
    handleError({
      error,
      userFriendlyMessage: 'Failed to get current enterprise',
      onError: currentEnterpriseActions().error,
    });
  }
}

/**
 * This provider replaces the following three since they all call the same
 * endpoint:
 * - useAutoGetCurrentEnterprise
 * - useAutoGetChannelsByCurrentUserEnterprise
 * - useAutoGetCurrentEnterpriseUsers
 */
export function useAutoGetCurrentEnterpriseCombined() {
  const { user } = useCurrentUserStorage();
  const enterpriseId = user?.enterprise?.id;

  React.useEffect(() => {
    if (!isNil(enterpriseId)) {
      getCurrentEnterpriseCombined(enterpriseId);
    }
  }, [enterpriseId]);
}

/* Pure `useAutoGetCurrentEnterprise` provider below */

// async function getCurrentEnterprise(id: Enterprise['id']) {
//   try {
//     currentEnterpriseActions().request();

//     const enterprise = await apiEnterpriseProvider.getEnterpriseById(id);

//     currentEnterpriseActions().receive(enterprise);
//   } catch (error) {
//     handleError({
//       error,
//       userFriendlyMessage: 'Failed to get current enterprise',
//       onError: currentEnterpriseActions().error,
//     });
//   }
// }

// export function useAutoGetCurrentEnterprise() {
//   const { user } = useCurrentUserStorage();
//   const enterpriseId = user?.enterprise?.id;

//   React.useEffect(() => {
//     if (!isNil(enterpriseId)) {
//       getCurrentEnterprise(enterpriseId);
//     }
//   }, [enterpriseId]);
// }
