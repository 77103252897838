import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { projectsActions, workspaceActions } from '@pn/core/storage';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiStackProjectDetailsMapper } from '@pn/services/api/stackdx/apiStackProjectDetailsMapper';
import { getStackProjectDetails } from '@pn/services/api/stackdx/apiStackProjectDetailsProvider';
import { apiStackWorkspaceItemToWorkspaceItemMapper } from '@pn/services/api/stackdx/apiStackWorkspaceItemMapper';
import React from 'react';

type Props = {
  workspaceItem: WorkspaceItem;
};

export function useConvertToStack({ workspaceItem }: Props) {
  const [isConverting, setIsConverting] = React.useState(false);

  const convertToStack = async (): Promise<WorkspaceItem | undefined> => {
    setIsConverting(true);

    try {
      const { stack_project_id } = await pnApiClient.request<{
        stack_project_id: string;
      }>({
        method: 'POST',
        url: `v2/lists/${workspaceItem.id}/stack`,
      });

      const stackProjectDetails =
        await getStackProjectDetails(stack_project_id);

      const project =
        apiStackProjectDetailsMapper.toDomainProject(stackProjectDetails);

      const newList =
        apiStackWorkspaceItemToWorkspaceItemMapper().toWorkspaceItem(
          stackProjectDetails,
          workspaceItem.sourceItem!
        );

      workspaceActions().remove(workspaceItem); // remove PN list

      projectsActions().add(project);
      projectsActions().select(project.id);

      workspaceActions().add(newList);
      workspaceActions().addToWorkspace(newList.id);
      workspaceActions().select(newList.id);

      return newList;
    } catch (error) {
      handleError({
        error,
        userFriendlyMessage: 'Conversion failed',
      });
    }

    setIsConverting(false);

    return undefined;
  };

  return { isConverting, convertToStack };
}
