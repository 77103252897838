import { Box, Button, Typography } from '@mui/material';
import type { GeoPoint } from '@pn/core/domain/geography';
import { round } from 'lodash-es';

type Props = {
  geoPoint: GeoPoint;
  onSetAsHomeLocation: (coords: GeoPoint) => void;
  onGetDirections: (coords: GeoPoint) => void;
};

export function MapboxPopup({
  geoPoint,
  onSetAsHomeLocation,
  onGetDirections,
}: Props) {
  return (
    <Box>
      <Typography variant="caption" textAlign="center">
        {round(geoPoint.lat, 5)}, {round(geoPoint.lon, 5)}
      </Typography>
      <Box mt={1}>
        <Button
          fullWidth
          variant="outlined"
          color="inherit"
          size="small"
          onClick={() => onSetAsHomeLocation(geoPoint)}
        >
          Set as Home Location
        </Button>
      </Box>
      <Box mt={1}>
        <Button
          fullWidth
          color="inherit"
          variant="outlined"
          size="small"
          onClick={() => onGetDirections(geoPoint)}
        >
          Get Directions to Here
        </Button>
      </Box>
    </Box>
  );
}
