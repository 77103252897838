import { Box } from '@mui/material';
import { createList } from '@pn/core/operations/workspace/crud/createList';
import { useCurrentUserStorage } from '@pn/core/storage';
import { isFreeCordova } from '@pn/core/utils/env';
import { DEFAULT_LIST_COLOR } from '@pn/services/styles/list';
import { PermissionButton } from '@pn/ui/custom-components/permission/PermissionButton';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'assert';

type Props = {
  onUploadLayer: () => void;
};

export const LibraryActionButtons = ({ onUploadLayer }: Props) => {
  const { user } = useCurrentUserStorage();
  const { openWorkspaceItemPanel } = useWorkspaceItemPanel();

  const handleCreateList = () => {
    assert(user, 'User must be defined to create a list');

    openWorkspaceItemPanel({
      item: createList({ user, color: DEFAULT_LIST_COLOR }),
    });
  };

  return (
    <Box display="flex" gap={2}>
      <PermissionButton
        permissionPath="lists"
        variant="contained"
        onClick={handleCreateList}
      >
        Import list
      </PermissionButton>
      {!isFreeCordova(user) && (
        <PermissionButton
          permissionPath="layers"
          variant="contained"
          onClick={onUploadLayer}
        >
          Upload GIS file
        </PermissionButton>
      )}
    </Box>
  );
};
