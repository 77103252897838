import { UserPlans } from '@pn/core/domain/user';
import { ConfigurationError } from '@pn/core/errors';
import { get, isObject, isString } from 'lodash-es';

export type Permission = {
  minimumPlan: UserPlans;
  message?: string;
  /**
   * Returns `true` when the exception passes.
   */
  testException?: (exception: unknown) => boolean;
};

function isPermission(arg: unknown): arg is Permission {
  return isObject(arg) && 'minimumPlan' in arg;
}

interface NestedPermissions {
  [x: string]: NestedPermissions | Permission;
}

export const permissions: NestedPermissions = {
  /* Apps */
  pnLogViewer: {
    minimumPlan: UserPlans.Professional,
  },
  /* Data */
  allData: {
    minimumPlan: UserPlans.Professional,
    message: 'Upgrade your account to access all data',
    testException: (exception) =>
      isString(exception) &&
      ['wells', 'wells_usa', 'grids'].includes(exception),
  },
  dataTable: {
    columns: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to access columns and table templates',
    },
    pagination: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to use pagination',
    },
    sorting: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to use sorting',
    },
    filtering: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to use filtering',
    },
    export: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to access exports',
    },
  },
  details: {
    selection: {
      minimumPlan: UserPlans.Professional,
    },
    export: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to download CSV',
    },
    report: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to access intel-templates',
    },
  },
  production: {
    chartControls: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to access all settings',
    },
    export: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to download CSV',
    },
    forecast: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to access the forecast',
    },
    aggregate: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to access aggregate production',
    },
    embed: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to generate embedded charts',
    },
  },
  documents: {
    export: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to download documents',
    },
  },
  dashboards: {
    minimumPlan: UserPlans.Professional,
  },
  /* User-generated content */
  lists: {
    minimumPlan: UserPlans.Free,
    message: 'Sign in to access lists',
    production: {
      minimumPlan: UserPlans.Professional,
      message:
        'Upgrade your account to access list aggregate/compare production',
    },
    export: {
      minimumPlan: UserPlans.Professional,
      message: 'Upgrade your account to access list exports',
    },
  },
  layers: {
    minimumPlan: UserPlans.Professional,
    message: 'Upgrade your account to upload GIS layers',
  },
  drawings: {
    minimumPlan:
      new Date() <= new Date('2024-11-16')
        ? UserPlans.Free
        : UserPlans.Professional,
    message:
      new Date() <= new Date('2024-11-16')
        ? 'Sign in to annotate the map'
        : 'Upgrade your account to annotate the map',
  },
  projects: {
    minimumPlan: UserPlans.Professional,
    message: 'Upgrade your account to use projects',
    sorting: {
      minimumPlan: UserPlans.EnterpriseMember,
    },
  },
  enterprise: {
    dashboard: {
      minimumPlan: UserPlans.EnterpriseAdmin,
    },
  },
  homeLocation: {
    minimumPlan: UserPlans.Free,
    message: 'Sign in to set your home location',
  },
  /* Tools */
  multiSelection: {
    minimumPlan: UserPlans.Professional,
    message: 'Upgrade your account to use multi-selection',
  },
  annotations: {
    minimumPlan: UserPlans.Professional,
  },
  portal: {
    minimumPlan: UserPlans.Professional,
    message: 'Upgrade your account to access multi-window workflows',
  },
  /* Power user features */
  terminal: {
    minimumPlan: UserPlans.EnterpriseMember,
    message: 'Upgrade your account to access the terminal',
  },
};

export function getPermissionByPath(path: string): Permission {
  const permission = get(permissions, path);

  if (isPermission(permission)) {
    return permission;
  } else {
    throw new ConfigurationError(
      `Permission for path "${path}" does not exist`
    );
  }
}
