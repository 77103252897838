import { dependencies } from '@pn/core/dependencies';
import { createChannel } from '@pn/core/domain/channel';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import { channelsSlice } from './channelsSlice';
import type { IChannelsActions, IChannelsStorage } from './ports';

export const useReduxChannelsStorage = (): IChannelsStorage => {
  const { name, getInitialState } = channelsSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    channels: useSliceSelector((state) => state.channels),
  };
};

export const reduxChannelsActions = (): IChannelsActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = channelsSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    receive: (channels) => dispatch(actions.receive(channels)),
    error: () => dispatch(actions.error()),
    reset: () => dispatch(actions.reset()),
    create: (channelForm) =>
      dispatch(actions.create(createChannel(channelForm))),
    update: (channelId, channelForm) =>
      dispatch(actions.update({ channelId, channelForm })),
    remove: (channelId) => dispatch(actions.remove(channelId)),
  };
};
