import { LicenseInfo } from '@mui/x-license';
import { useAutoTriggerAuth } from '@pn/core/operations/app';
import { useAppStorage, useCurrentUserStorage } from '@pn/core/storage';
import { isEmbedded } from '@pn/core/utils/embedded';
import env from '@pn/core/utils/env';
import { Auth0AuthProvider } from '@pn/services/authentication/auth0/Auth0AuthenticationProvider';
import { ErrorBoundaryProvider } from '@pn/services/loggers/AppSignalErrorBoundaryProvider';
import { ReduxStoreProvider } from '@pn/services/redux/ReduxStoreProvider';
import { TerminalDialog } from '@pn/services/terminal';
import { ErrorPage } from '@pn/ui/error-page';
import { PetroNinjaThemeProvider } from '@pn/ui/theme/PetroNinjaThemeProvider';
import { isNil } from 'lodash-es';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { useAuthenticationService } from 'src/application/externalDependencies';
import history from 'src/application/history';
import { useAutoInitializeApp } from 'src/application/useInitializeApp';
import { store } from 'src/storage/store';
import { ReactContextsProvider } from 'src/ui/AppProviders';
import { Main } from 'src/ui/Main';
import { FullHeightCircularLoader } from 'src/ui/components/FullHeightCircularLoader';
import { NoInternetConnectionDialog } from 'src/ui/components/NoInternetConnectionDialog';
import { Effects } from 'src/ui/effects';
import { usePreloadFonts } from 'src/ui/preload';

const LoginByInvite = React.lazy(
  () => import('src/ui/enterprise/LoginByInvite')
);
const EnterpriseDashboard = React.lazy(
  () => import('src/ui/enterprise/EnterpriseDashboard')
);
const Dashboards = React.lazy(
  () => import('src/ui/dashboards/DashboardCatalog')
);
const Privacy = React.lazy(() => import('src/ui/legal/Privacy'));
const Terms = React.lazy(() => import('src/ui/legal/Terms'));
const SaasTerms = React.lazy(() => import('src/ui/legal/SaasTerms'));
const ProductionView = React.lazy(() => import('src/ui/production'));
const Account = React.lazy(() => import('src/ui/user/Account'));
const Report = React.lazy(() => import('src/ui/report/Report'));
const DeclineAnalysis = React.lazy(
  () => import('src/ui/decline/DeclineAnalysis')
);

LicenseInfo.setLicenseKey(env.DATA_GRID_PRO_LICENSE_KEY);

export function App() {
  return (
    <ReduxStoreProvider store={store}>
      <PetroNinjaThemeProvider>
        <Auth0AuthProvider history={history}>
          <ErrorBoundaryProvider errorPage={ErrorPage}>
            <AppRoutesGateway />
          </ErrorBoundaryProvider>
        </Auth0AuthProvider>
      </PetroNinjaThemeProvider>
    </ReduxStoreProvider>
  );
}

/**
 * We don't need to process any routes until the app is initialized.
 */
function AppRoutesGateway() {
  const { isAppInitialized } = useAppStorage();

  useAutoInitializeApp(isAppInitialized);

  if (!isAppInitialized) {
    return <FullHeightCircularLoader text="Loading Petro Ninja..." />;
  }

  return (
    <ReactContextsProvider>
      <AppRoutes />
    </ReactContextsProvider>
  );
}

const appRoutes = [
  '/search/:searchQuery',
  '/lists/:itemId',
  '/annotations/:itemId',
  '/drawings/:itemId',
  '/:dataType/:id',
  '/:dataType',
  '*',
];

function AppRoutes() {
  usePreloadFonts();

  const { user } = useCurrentUserStorage();

  const { isAuthenticating, isAuthenticated } = useAuthenticationService();
  const isAuthTriggered = useAutoTriggerAuth();

  const isAuthLoading =
    isAuthenticating || (isAuthenticated && isNil(user)) || !isAuthTriggered;

  if (isEmbedded()) {
    return (
      <Router history={history}>
        <Switch>
          <Route path={appRoutes} component={Main} />
        </Switch>
      </Router>
    );
  }

  return (
    <Router history={history}>
      {isAuthLoading ? (
        <FullHeightCircularLoader text="Loading Petro Ninja..." />
      ) : (
        <React.Suspense
          fallback={<FullHeightCircularLoader text="Loading Petro Ninja..." />}
        >
          <Switch>
            <Redirect from="/trial" to="/" />
            <Redirect from="/signin" to="/" />
            <Redirect from="/signup" to="/" />
            <Redirect from="/register" to="/" />
            <Redirect from="/coordinates/:param" to="/search/:param" />
            <Redirect from="/location/:param" to="/search/:param" />
            <Route path="/login" component={LoginByInvite} />
            <Route path="/account" component={Account} />
            <Route path="/enterprise" component={EnterpriseDashboard} />
            <Route path="/dashboards" component={Dashboards} />
            <Route path="/production/:type/:id" component={ProductionView} />
            {/* order matters */}
            <Route path="/production/:type" component={ProductionView} />
            <Route
              exact
              path="/wells/:id/decline_analysis"
              component={DeclineAnalysis}
            />
            <Route exact path="/:dataType/:id/report" component={Report} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="/saas" component={SaasTerms} />
            <Route path={appRoutes} component={Main} />
          </Switch>
        </React.Suspense>
      )}

      <Effects />

      <NoInternetConnectionDialog />
      <TerminalDialog />
    </Router>
  );
}
