import { LIST_COMPARE_MAX_ITEMS } from '@pn/core/limits';
import type { DataItemId } from '@pn/core/domain/data';
import { addProductionDerivatives } from '@pn/core/domain/production';
import type { IProductionProvider } from '@pn/core/providers/production/ports';
import { isNil } from 'lodash-es';
import { pnApiClient } from '../pnApiClient';
import { apiProductionItemMapper } from './apiProductionMapper';
import type { ApiProductionItem } from './types';

export const getProduction = async (
  dataType: string,
  id: DataItemId,
  token?: string
) => {
  const apiProductionItems = await pnApiClient.request<ApiProductionItem[]>({
    url: `v2/${dataType}/${id}/production`,
    payload: {
      token,
    },
  });

  const productionItems = addProductionDerivatives(
    apiProductionItems.map(apiProductionItemMapper.toDomainProductionItem)
  );

  return productionItems;
};

export const getMultiProduction = async (
  dataType: string,
  ids: DataItemId[]
) => {
  const apiProductionItems = await pnApiClient.request<ApiProductionItem[]>({
    method: 'POST',
    url: `v2/${dataType}/production`,
    payload: {
      ids,
    },
  });

  const productionItems = addProductionDerivatives(
    apiProductionItems.map(apiProductionItemMapper.toDomainProductionItem)
  );

  return productionItems;
};

export const getListAggregateProduction = async (
  listId: string,
  token?: string
) => {
  const apiProductionItems = await pnApiClient.request<ApiProductionItem[]>({
    url: `v2/list_aggregate/${listId}`,
    payload: {
      token,
    },
  });

  const productionItems = addProductionDerivatives(
    apiProductionItems
      .filter(({ volume_units }) => !isNil(volume_units)) // TODO figure out where these are coming from
      .map(apiProductionItemMapper.toDomainProductionItem)
  );

  return productionItems;
};

/**
 * Returns 3 categories of Production Items:
 *
 *   1. Monthly averages for the entire list
 *   2. N list items by `ids` (0 < N <= 50)
 *   3. A semi-random sample of up to (50 - N) list items
 *
 * Every category includes ALL production series (oil, gas, water, etc.)
 */
export const getListCompareProduction = async (
  listId: string,
  selectedItemIds: DataItemId[],
  token?: string
) => {
  if (selectedItemIds.length > LIST_COMPARE_MAX_ITEMS) {
    throw new Error(
      `Exceeded maximum number of requested items (${LIST_COMPARE_MAX_ITEMS})`
    );
  }

  const apiProductionItems = await pnApiClient.request<ApiProductionItem[]>({
    method: 'POST',
    url: `v2/list_compare/${listId}`,
    payload: {
      ids: selectedItemIds,
      token,
    },
  });

  const productionItems = addProductionDerivatives(
    apiProductionItems
      .filter(({ volume_units }) => !isNil(volume_units)) // TODO figure out where these are coming from
      .map(apiProductionItemMapper.toDomainProductionItem)
  );

  return productionItems;
};

export const getAggregateProduction = async (wellIds: DataItemId[]) => {
  const apiProductionItems = await pnApiClient.request<ApiProductionItem[]>({
    method: 'POST',
    url: 'v2/data/aggregate_production',
    payload: {
      well_ids: wellIds,
    },
  });

  const productionItems = addProductionDerivatives(
    apiProductionItems
      .filter(({ volume_units }) => !isNil(volume_units)) // TODO figure out where these are coming from
      .map(apiProductionItemMapper.toDomainProductionItem)
  );

  return productionItems;
};

export const getCompareProduction = async (
  wellIds: string[],
  selectedWellIds: string[]
) => {
  if (selectedWellIds.length > LIST_COMPARE_MAX_ITEMS) {
    throw new Error(
      `Exceeded maximum number of requested items (${LIST_COMPARE_MAX_ITEMS})`
    );
  }

  const apiProductionItems = await pnApiClient.request<ApiProductionItem[]>({
    method: 'POST',
    url: 'v2/data/compare_production',
    payload: {
      well_ids: wellIds,
      ids: selectedWellIds,
    },
  });

  const productionItems = addProductionDerivatives(
    apiProductionItems
      .filter(({ volume_units }) => !isNil(volume_units)) // TODO figure out where these are coming from
      .map(apiProductionItemMapper.toDomainProductionItem)
  );

  return productionItems;
};

export const getEmbeddedProductionToken = async (
  params: Record<string, unknown>
) => {
  const { token } = await pnApiClient.request<{ token: string }>({
    method: 'POST',
    url: 'v2/token',
    payload: params,
  });

  return token;
};

export const apiProductionProvider: IProductionProvider = {
  getProduction,
  getMultiProduction,
  getListAggregateProduction,
  getListCompareProduction,
  getAggregateProduction,
  getCompareProduction,
  getEmbeddedProductionToken,
};
