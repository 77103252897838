import { isProduction } from '@pn/core/utils/env';
import { isNil } from 'lodash-es';

const COLOR = 'color: #9C27B0';

export class Timer {
  static startDates: Record<string, Date> = {};
  static history: Record<string, number[]> = {};

  static start(key: string) {
    this.startDates[key] = new Date();
    if (!isProduction()) console.log(`%c[${key}] Timer started.`, COLOR);
  }

  static isRunning(key: string) {
    return !isNil(this.startDates[key]);
  }

  static read(key: string, label?: string) {
    if (!this.isRunning(key)) return;

    const timeElapsed = new Date().getTime() - this.startDates[key].getTime();

    if (this.history[key]) {
      this.history[key].push(timeElapsed);
    } else {
      this.history[key] = [timeElapsed];
    }

    if (!isProduction())
      console.log(
        `%c[${key}] Time elapsed:`,
        COLOR,
        timeElapsed,
        'ms',
        label ?? ''
        // `(${round(mean(this.history[key]), 1)} @ ${this.history[key].length})`
      );
  }

  static stop(key: string) {
    this.read(key);
    delete this.startDates[key];
  }
}

export const timers = {
  mapClickToRequest: 'map click to data request',
};
