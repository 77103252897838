import NorthEastIcon from '@mui/icons-material/NorthEast';
import { Box, IconButton, Skeleton } from '@mui/material';
import { Table } from '@pn/core/domain/data-info';
import { minifyZonalQuery } from '@pn/core/domain/zone';
import { compressAndEncode } from '@pn/services/utils/compression';
import { isNil } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  iframeContainer: {
    position: 'relative',
    width: '100%',
    height: 300,
    overflow: 'hidden',
  },
  iframe: {
    border: 0,
    width: '100%',
    height: '100%',
  },
  iframeOpenButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
  },
  skeleton: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
}));

type Props = {
  module: Table['module'] | undefined;
};

export const TableModule = ({ module }: Props) => {
  const { classes, theme } = useStyles();

  if (isNil(module)) return null;

  switch (module.type) {
    case 'formations_viewer': {
      const searchParams = new URLSearchParams({
        t: theme.palette.mode,
        z: compressAndEncode(
          module.zonalQueries.map((z) => minifyZonalQuery(z))
        ),
      });
      const url = `https://formations.petroninja.com/ab?${searchParams.toString()}`;

      return (
        <Box className={classes.iframeContainer}>
          <Skeleton
            variant="rounded"
            width="100%"
            height="100%"
            className={classes.skeleton}
          />
          <iframe
            title="Formations Viewer"
            src={`${url}&e=1`}
            className={classes.iframe}
          />
          <IconButton
            component="a"
            href={url}
            target="_blank"
            className={classes.iframeOpenButton}
          >
            <NorthEastIcon />
          </IconButton>
        </Box>
      );
    }
  }
};
