import { alpha } from '@mui/material';
import { zIndex } from '@pn/maps/src/ui/zIndex'; // TEMP
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    display: 'grid',
    gridTemplateAreas: `
      "app-bar app-bar"
      "side-bar content"
    `,
    gridTemplateRows: 'min-content',
    gridTemplateColumns: 'min-content',
    width: 'calc(100% - 300px)',
    background: theme.palette.background.paper,
    zIndex: zIndex(theme).library,
    '@media print': {
      display: 'none',
    },
  },
  appBar: {
    gridArea: 'app-bar',
    background: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  closeButton: {
    marginRight: theme.spacing(1),
  },
  sideBar: {
    gridArea: 'side-bar',
    width: 250,
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'auto',
  },
  sideBarList: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  text: {
    flex: '0 0 auto',
  },
  title: {
    color: theme.palette.secondary.main,
  },
  content: {
    gridArea: 'content',
    display: 'grid',
    gridTemplateAreas: `
      "controls"
      "table"
    `,
    gridTemplateRows: 'min-content',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    overflow: 'auto',
  },
  controls: {
    gridArea: 'controls',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    height: 40,
  },
  tableContainer: {
    gridArea: 'table',
    overflow: 'auto',
  },
  dataGrid: {
    '& .MuiDataGrid-columnHeader.checkbox-header': {
      padding: 0,
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      '&:hover': {
        '.project-icon-button': {
          display: 'inline-flex',
        },
      },
    },
    '& .MuiDataGrid-cell': {
      outline: 'none !important',
    },
  },
  tabContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(4)}`,
    textTransform: 'none',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  tabTitle: {
    display: 'inline-flex',
  },
  stackListItemButton: {
    paddingLeft: 5,
    paddingTop: 0,
    paddingBottom: 0,
  },
  categoryIcon: {
    width: 16,
    marginLeft: theme.spacing(1),
  },
  categoryLogo: {
    height: 48,
  },
  categoryTitle: {
    cursor: 'pointer',
    userSelect: 'none',
  },
}));
