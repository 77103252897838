import { dependencies } from '@pn/core/dependencies';
import type { Project } from '@pn/core/domain/project';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import {
  projectsActions,
  useCurrentUserStorage,
  useProjectsStorage,
  workspaceActions,
} from '@pn/core/storage';
import { isNil } from 'lodash-es';
import React from 'react';

export function useQuickProjectActions(item: WorkspaceItem) {
  const { notificationService } = dependencies;

  const { user } = useCurrentUserStorage();
  const { projects } = useProjectsStorage();

  const generalProject = React.useMemo(
    () => getGeneralProject(projects),
    [projects]
  );

  const isItemShared: boolean = React.useMemo(() => {
    if (item.isTemporary) return item._isTemporaryShared;
    if (isNil(generalProject)) return false;

    return generalProject.workspaceItemIds.some((id) => id === item.id);
  }, [generalProject, item.id, item.isTemporary, item._isTemporaryShared]);

  const toggleShared = (checked: boolean): void => {
    if (item.isTemporary) {
      workspaceActions().updateIsTemporaryShared(item.id, checked);
      return;
    }

    if (isNil(generalProject)) {
      notificationService.notify('General project not found', 'error');
      return;
    }

    if (checked) {
      projectsActions().addItem(generalProject.id, item);
    } else {
      projectsActions().removeItem(generalProject.id, item);
    }
  };

  return {
    showSharingToggle: !isNil(user?.enterprise),
    isItemShared,
    toggleShared,
  };
}

export function getGeneralProject(projects: Project[]): Project | undefined {
  return projects.find((project) => project.name === 'General');
}
