import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { ChartIcon } from './ChartIcon';

const DOT_ANIMATION_SPEED = 400; // ms

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  iconContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: 64,
    color: 'rgba(0, 0, 0, 0.175)',
  },
  dot: {
    transition: `opacity ${DOT_ANIMATION_SPEED / 2}ms ease-in-out`,
  },
}));

export const ChartLoadingIndicator = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Skeleton variant="rounded" width="100%" height="100%" />
      <Box className={classes.iconContainer}>
        <ChartIcon className={classes.icon} />
        <Box mt={1} />
        <Typography color="textSecondary">
          Loading production data{AnimatedDots()}
        </Typography>
      </Box>
    </Box>
  );
};

// render three dots that animate their opacity
function AnimatedDots() {
  const { classes } = useStyles();

  const [dots, setDots] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => (dots + 1) % 4);
    }, DOT_ANIMATION_SPEED);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <span className={classes.dot} style={{ opacity: dots > 0 ? 1 : 0 }}>
        .
      </span>
      <span className={classes.dot} style={{ opacity: dots > 1 ? 1 : 0 }}>
        .
      </span>
      <span className={classes.dot} style={{ opacity: dots > 2 ? 1 : 0 }}>
        .
      </span>
    </>
  );
}
