import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { workspaceActions } from '@pn/core/storage';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';

type Props = {
  item: WorkspaceItem;
  disabled: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onRename: () => void;
};

export const DrawingPanelMenu = ({
  item,
  disabled,
  anchorEl,
  onClose,
  onRename,
}: Props) => {
  const { closeWorkspaceItemPanel } = useWorkspaceItemPanel();

  const handleRename = () => {
    onRename();
    onClose();
  };

  const handleDelete = () => {
    workspaceActions().remove(item);

    onClose();
    closeWorkspaceItemPanel(true);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      slotProps={{
        paper: {
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        },
      }}
    >
      <MenuItem disabled={disabled} onClick={handleRename}>
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Rename</ListItemText>
      </MenuItem>
      <MenuItem disabled={disabled} onClick={handleDelete}>
        <ListItemIcon>
          <DeleteForeverIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </Menu>
  );
};
