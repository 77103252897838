import { useDroppable } from '@dnd-kit/core';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const TRASH_BIN_ID = 'trash-bin';

const useStyles = makeStyles()((theme) => ({
  trashBin: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 64,
    padding: theme.spacing(1.5),
    background: `repeating-linear-gradient(
      45deg,
      ${theme.palette.background.paper},
      ${theme.palette.background.paper} 10px,
      ${theme.palette.action.selected} 10px,
      ${theme.palette.action.selected} 20px
    )`,
    color: theme.palette.text.secondary,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: `repeating-linear-gradient(
        45deg,
        ${theme.palette.background.paper},
        ${theme.palette.background.paper} 10px,
        ${
          theme.palette.mode === 'light'
            ? theme.palette.error.light
            : theme.palette.warning.main
        } 10px,
        ${
          theme.palette.mode === 'light'
            ? theme.palette.error.light
            : theme.palette.warning.main
        } 20px
      )`,
      opacity: 0,
      transition: 'opacity 200ms ease',
    },
  },
  trashBinActive: {
    color: theme.palette.text.primary,
    '&:before': {
      opacity: 1,
    },
  },
  textBox: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    zIndex: 1,
  },
}));

export const WorkspaceTrashBin = () => {
  const { classes, cx } = useStyles();

  const { isOver, setNodeRef } = useDroppable({
    id: TRASH_BIN_ID,
  });

  return (
    <Box
      ref={setNodeRef}
      className={cx(
        classes.trashBin,
        isOver ? classes.trashBinActive : undefined
      )}
    >
      <Box className={classes.textBox}>
        <Typography variant="body2">
          Drop here to remove from workspace
        </Typography>
      </Box>
    </Box>
  );
};
