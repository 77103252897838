import type { IProjectMapper } from '@pn/core/mappers/project';
import { isEmpty } from 'lodash-es';
import { preferredOrder } from './apiStackProjectMapper';
import { getStackItemId } from './apiStackWorkspaceItemMapper';
import type { StackProjectWithListIds } from './types';

export const apiStackProjectDetailsMapper: IProjectMapper<
  StackProjectWithListIds,
  never
> = {
  toDomainProject: (stackProjectDetails) => {
    return {
      id: `stackdx|${stackProjectDetails.projectId}`,
      name: stackProjectDetails.description,
      folder: 'StackDX',
      createdAt: stackProjectDetails.createDate,
      updatedAt: stackProjectDetails.updateDate,
      createdBy: undefined,
      channelId: undefined,
      workspaceItemIds: stackProjectDetails.lists
        .filter((list) => !isEmpty(list.ids))
        .sort(
          (a, b) =>
            preferredOrder.indexOf(b.dataType) -
            preferredOrder.indexOf(a.dataType)
        )
        .map((list) =>
          getStackItemId(stackProjectDetails.projectId, list.sourceLayerId)
        ),
      toggleAllEnabled: true,
      isGlobal: false,
      origin: 'stackdx',
      metadata: {
        dataOriginDescription: stackProjectDetails.dataOriginDesc,
      },
    };
  },
  toTargetProjectPayload: () => {
    throw new Error('Manipulation of Stack projects is not supported');
  },
};
